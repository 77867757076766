import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Alert,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  ListItemText,
  Container,
  Tabs,
  Tab,
  Divider,
  Stack,
  List,
  ListItem,
  CircularProgress,
  Grid,
  Chip,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import { statesList } from "../../../general/util/statesList";
import LeadsByStateAndAgeChart from "../../../general/charts/LeadsByStateAndAgeChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OverrunCheckoutForm from "./components/OverrunCheckoutForm";
import { useNavigate } from "react-router-dom";

const ClaimOverrunLeads = () => {
  const currentUser = decode();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [overrunProducts, setOverrunProducts] = useState([]);
  const [leadProducts, setLeadProducts] = useState([]);
  const [overrunOrders, setOverrunOrders] = useState([]);
  const [claims, setClaims] = useState([]);
  const [availableTokens, setAvailableTokens] = useState(0);
  const [leadsRequest, setLeadsRequest] = useState({
    "0-2days": 0,
    "2days-7days": 0,
    "7days-30days": 0,
    "30days-90days": 0,
    "90days+": 0,
  });
  const [selectedStates, setSelectedStates] = useState([]);
  const [overrunProductId, setOverrunProductId] = useState("");
  const [openClaimDialog, setOpenClaimDialog] = useState(false);
  const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
  const [purchaseTokens, setPurchaseTokens] = useState(10);
  const [error, setError] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [acceptNoRefunds, setAcceptNoRefunds] = useState(false);
  const [acceptNoReplacements, setAcceptNoReplacements] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoadingStripe, setIsLoadingStripe] = useState(true);
  const [stripeError, setStripeError] = useState("");
  const [claimResponse, setClaimResponse] = useState(null);
  const [completionDialogOpen, setCompletionDialogOpen] = useState(false);
  const [isLoadingTokens, setIsLoadingTokens] = useState(false);
  const [tokenError, setTokenError] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  // Add this new useEffect to fetch the Stripe key when component mounts
  useEffect(() => {
    const initializeStripe = async () => {
      try {
        if (!overrunProductId) {
          return;
        }
        const response = await customFetch(
          `/v1/orders/payment-intent-key?product_id=${overrunProductId}`
        );
        if (!response.ok) {
          throw new Error("Failed to load Stripe configuration");
        }
        const data = await response.json();
        if (!data.success || !data.key) {
          throw new Error("Invalid Stripe configuration received");
        }

        setStripePromise(loadStripe(data.key));
      } catch (error) {
        console.error("Error initializing Stripe:", error);
        setStripeError(
          "Failed to initialize payment system. Please try again later."
        );
      } finally {
        setIsLoadingStripe(false);
      }
    };

    initializeStripe();
  }, [overrunProductId]);

  useEffect(() => {
    if (overrunProducts.length > 0) {
      const productId = overrunProducts[selectedTab]?.id;
      if (productId) {
        fetchOverrunOrders(productId);
        fetchAvailableTokens(productId);
        fetchClaimsByProduct(productId);
      }
    }
  }, [overrunProducts, selectedTab]);

  const fetchProducts = async () => {
    try {
      const response = await customFetch(`/v1/products/product-catalog`);
      const data = await response.json();
      const overrunProducts = data
        .filter((product) => product.product_key === "agent-overrun")
        .map((product) => ({
          ...product,
          price: product.unit_amount ? product.unit_amount / 100 : 0,
          currency: product.currency || "USD",
        }));
      setProducts(data);
      setOverrunProducts(overrunProducts);
      setLeadProducts(
        data.filter((product) => product.product_key === "agent-lead")
      );
      if (overrunProducts.length > 0) {
        setOverrunProductId(overrunProducts[0].id);
        setPurchaseTokens(overrunProducts[0].order_minimum || 10);
      }
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  const fetchOverrunOrders = async (productId) => {
    try {
      const response = await customFetch(
        `/v1/orders/overruns?product_id=${productId}`
      );
      const data = await response.json();
      const ordersWithProductNames = data.map((order) => ({
        ...order,
        product_id:
          products.find((p) => p.id === order.product_id)?.name ||
          order.product_id,
        created_time: new Date(order.created_time),
      }));
      setOverrunOrders(ordersWithProductNames);
    } catch (error) {
      console.error("Error fetching overrun orders", error);
    }
  };

  const fetchAvailableTokens = async (productId) => {
    try {
      setIsLoadingTokens(true);
      setTokenError("");
      const response = await customFetch(
        `/v1/orders/overruns/available-tokens?product_id=${productId}`
      );
      if (response.ok) {
        const data = await response.json();
        setAvailableTokens(data.availableTokens);
      } else {
        throw new Error("Failed to fetch available tokens");
      }
    } catch (error) {
      console.error("Error fetching available tokens:", error);
      setTokenError("Failed to load available tokens");
    } finally {
      setIsLoadingTokens(false);
    }
  };

  const fetchClaimsByProduct = async (productId) => {
    try {
      const response = await customFetch(
        `/v1/orders/overruns/claims?product_id=${productId}`
      );
      const data = await response.json();
      const claimsbyProduct = data.map((claim) => ({
        ...claim,
        product_id:
          products.find((p) => p.id === claim.product_id)?.name ||
          claim.product_id,
      }));
      setClaims(claimsbyProduct);
    } catch (error) {
      console.error("Error fetching claims", error);
    }
  };

  const handleLeadsRequestChange = (event) => {
    let value = parseInt(event.target.value, 10);
    // Ensure the value is not negative, default to 0 if it's invalid or negative
    value = isNaN(value) || value < 0 ? 0 : value;
    setLeadsRequest({
      ...leadsRequest,
      [event.target.name]: value,
    });
  };

  const handleStatesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStates(typeof value === "string" ? value.split(",") : value);
  };

  const handleClaimLeads = async () => {
    const hasAtLeastOneLeadRequested = Object.values(leadsRequest).some(
      (value) => value > 0
    );

    if (!overrunProductId) {
      setError("Please select an overrun product.");
      return;
    }
    if (!hasAtLeastOneLeadRequested) {
      setError("Please request at least one lead.");
      return;
    }
    if (selectedStates.length === 0) {
      setError("Please select at least one state.");
      return;
    }

    const requestBody = {
      leads_request: leadsRequest,
      states: selectedStates,
      overrun_product_id: overrunProductId,
    };

    try {
      setIsClaiming(true);
      const response = await customFetch(`/v1/orders/overruns/claims`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to claim leads");
      }

      const data = await response.json();
      setClaimResponse(data);
      setCompletionDialogOpen(true);
      setOpenClaimDialog(false);

      // Refresh data after successful claim
      fetchAvailableTokens(overrunProductId);
      fetchClaimsByProduct(overrunProductId);
    } catch (error) {
      console.error("Error claiming leads:", error);
      setError(error.message || "Failed to claim leads");
    } finally {
      setIsClaiming(false);
    }
  };

  const handlePurchaseTokens = async () => {
    if (!acceptNoRefunds || !acceptNoReplacements) {
      setError("Please accept both terms before proceeding");
      return;
    }

    const selectedProduct = overrunProducts[selectedTab];
    if (!selectedProduct) {
      setError("Please select a valid product");
      return;
    }

    if (purchaseTokens < selectedProduct.order_minimum) {
      setError(
        `Minimum order quantity is ${selectedProduct.order_minimum} tokens`
      );
      return;
    }

    try {
      setError("");
      setIsLoadingStripe(true);

      const response = await customFetch(
        "/v1/orders/overruns/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            total_tokens_ordered: purchaseTokens,
            accept_no_refunds: acceptNoRefunds,
            accept_no_replacements: acceptNoReplacements,
            product_id: selectedProduct.id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const data = await response.json();

      // Initialize Stripe first
      const stripe = await loadStripe(data.publishableKey);
      if (!stripe) {
        throw new Error("Failed to initialize Stripe");
      }

      setStripePromise(stripe);
      setClientSecret(data.clientSecret);
      setOrderDetails({
        ...data.orderDetails,
        orderId: data.order_id,
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setError("Failed to initiate payment process. Please try again.");
    } finally {
      setIsLoadingStripe(false);
    }
  };

  const handleOpenClaimDialog = () => {
    setError("");
    setOpenClaimDialog(true);
  };

  const handleCloseClaimDialog = () => {
    setOpenClaimDialog(false);
    setError("");
    setLeadsRequest({
      "0-2days": 0,
      "2days-7days": 0,
      "7days-30days": 0,
      "30days-90days": 0,
      "90days+": 0,
    });
    setSelectedStates([]);
  };

  const handleOpenPurchaseDialog = () => {
    setOpenPurchaseDialog(true);
  };

  const handleClosePurchaseDialog = () => {
    setOpenPurchaseDialog(false);
    setError("");
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setOverrunProductId(overrunProducts[newValue].id);
    setPurchaseTokens(overrunProducts[newValue].order_minimum || 10);
  };

  const columns = [
    { field: "id", headerName: "Token Order ID", flex: 0.75 },
    { field: "total_tokens_ordered", headerName: "Tokens Purchased", flex: 1 },
    { field: "order_status", headerName: "Order Status", flex: 1 },
    { field: "created_time", headerName: "Order Date", flex: 1, type: "date" },
  ];

  const claimColumns = [
    { field: "order_id", headerName: "Reference Order", flex: 1 },
    { field: "product_id", headerName: "Product", flex: 1 },
    { field: "tokens_used", headerName: "Tokens Used", flex: 1 },
    { field: "total_leads", headerName: "Leads Claimed", flex: 1 },
    { field: "states", headerName: "States", flex: 1 },
  ];

  const tokenCosts = {
    "0-2days": 5,
    "2days-7days": 4,
    "7days-30days": 3,
    "30days-90days": 2,
    "90days+": 1,
  };

  const totalTokens = Object.entries(leadsRequest).reduce(
    (acc, [key, value]) =>
      acc + (tokenCosts[key] || 0) * (isNaN(value) ? 0 : value),
    0
  );

  const hasAtLeastOneLeadRequested = Object.values(leadsRequest).some(
    (value) => value > 0
  );

  const isFormValid =
    overrunProductId && selectedStates.length > 0 && hasAtLeastOneLeadRequested;

  // Update the Dialog that uses Elements to handle loading and error states
  const renderStripeDialog = () => {
    if (!clientSecret) return null;

    return (
      <Dialog
        open={!!clientSecret}
        onClose={() => setClientSecret(null)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Complete Your Payment</DialogTitle>
        <DialogContent>
          {isLoadingStripe ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : stripeError ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {stripeError}
            </Alert>
          ) : stripePromise ? (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <OverrunCheckoutForm
                orderDetails={orderDetails}
                orderId={orderDetails.orderId}
              />
            </Elements>
          ) : (
            <Alert severity="error" sx={{ mb: 2 }}>
              Unable to initialize payment system. Please try again later.
            </Alert>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className="content">
      <Container disableGutters sx={{ width: "100vp", padding: 0, margin: 0 }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main",
            marginBottom: 2,
            textAlign: "left",
          }}
        >
          Claim Overrun Leads
        </Typography>

        {/* Product Selection Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="overrun products tabs"
          >
            {overrunProducts.map((product, index) => (
              <Tab
                key={product.id}
                label={product.name}
                id={`overrun-tab-${index}`}
                aria-controls={`overrun-tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </Box>

        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box sx={{ maxWidth: "1000px", mb: 3, mx: 2 }}>
            <Card
              elevation={3}
              sx={{
                background: (theme) =>
                  `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
                color: "white",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <CardContent sx={{ position: "relative", zIndex: 1 }}>
                {isLoadingTokens ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p={3}
                  >
                    <CircularProgress sx={{ color: "white" }} />
                  </Box>
                ) : tokenError ? (
                  <Alert
                    severity="error"
                    sx={{
                      "& .MuiAlert-icon": {
                        color: "error.main",
                      },
                    }}
                  >
                    {tokenError}
                  </Alert>
                ) : (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <Box>
                        <Typography variant="overline" sx={{ opacity: 0.8 }}>
                          Current Product
                        </Typography>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {overrunProducts[selectedTab]?.name || "Loading..."}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <Typography variant="overline" sx={{ opacity: 0.8 }}>
                          Available Tokens
                        </Typography>
                        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                          {availableTokens}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        display="flex"
                        justifyContent={{ xs: "center", md: "flex-end" }}
                      >
                        <Button
                          variant="contained"
                          size="large"
                          onClick={handleOpenPurchaseDialog}
                          sx={{
                            bgcolor: "white",
                            color: "primary.main",
                            "&:hover": {
                              bgcolor: "grey.100",
                            },
                            px: 4,
                            py: 1.5,
                          }}
                          startIcon={<LocalOfferIcon />}
                        >
                          Purchase More Tokens
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0.1,
                  background:
                    'url("data:image/svg+xml,%3Csvg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-.895-3-2-3-3 .895-3 2 .895 3 2 3zm63 31c1.657 0 3-1.343 3-3s-.895-3-2-3-3 .895-3 2 .895 3 2 3zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM34 90c1.657 0 3-1.343 3-3s-.895-3-2-3-3 .895-3 2 .895 3 2 3zm56-76c1.657 0 3-1.343 3-3s-.895-3-2-3-3 .895-3 2 .895 3 2 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z" fill="%23ffffff" fill-opacity="1" fill-rule="evenodd"/%3E%3C/svg%3E")',
                  backgroundSize: "24px 24px",
                }}
              />
            </Card>
          </Box>
        </Box>

        <Typography
          variant="body1"
          sx={{
            marginBottom: 2,
            textAlign: "left",
          }}
        >
          Overrun leads are leads which have been generated by the respective
          facebook campaign but didn't have an active order to assign them to.
          They are a result of how facebook turns off advertisements which
          sometimes results in more leads being generated than expected.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, mb: 0.5, lineHeight: "1.2" }}>
          When claiming overrun leads, each lead type has a specific token cost
          associated with it. Here is a breakdown to help you understand the
          value:
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
            pl: 6,
            "& .MuiListItem-root": { display: "list-item", py: 0 },
          }}
        >
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads older than 90 days cost 1 token each." />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads 30-90 days old cost 2 tokens each." />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads 7-30 days old cost 3 tokens each." />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads 2-7 days old cost 4 tokens each." />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads 0-2 days old cost 5 tokens each." />
          </ListItem>
        </List>
        <Typography variant="body1" sx={{ mt: 0.5, lineHeight: "1.2" }}>
          This means that newer leads require more tokens, reflecting their
          recent activity and higher potential value.
        </Typography>
      </Container>
      <Box sx={{ width: "100%", margin: "20px 0px" }}>
        <Typography variant="h4" component="h4" gutterBottom>
          Token Orders
        </Typography>
        <DataGridPro
          rows={overrunOrders}
          columns={columns}
          pageSize={5}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          pagination
          autoHeight
        />
      </Box>
      <Box sx={{ width: "100%", margin: "20px 0px" }}>
        <Typography variant="h4" component="h4" gutterBottom>
          Claims
        </Typography>

        <Box display="flex" justifyContent="left" sx={{ margin: "20px 0px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenClaimDialog}
          >
            Claim Leads
          </Button>
        </Box>
        <DataGridPro
          rows={claims}
          columns={claimColumns}
          pageSize={5}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          pagination
          autoHeight
        />
        <LeadsByStateAndAgeChart productId={overrunProductId} />
      </Box>

      <Dialog open={openClaimDialog} onClose={handleCloseClaimDialog}>
        <DialogTitle>Claim Overrun Leads</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the number of leads to claim and the states you want to
            target.
          </DialogContentText>
          <TextField
            select
            margin="dense"
            label="Overrun Product ID"
            fullWidth
            value={overrunProductId}
            onChange={(e) => setOverrunProductId(e.target.value)}
            error={!overrunProductId}
            helperText={
              !overrunProductId ? "Please select an overrun product." : ""
            }
          >
            {overrunProducts.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </TextField>
          {Object.keys(leadsRequest).map((key) => (
            <TextField
              key={key}
              margin="dense"
              label={`${key} Leads`}
              type="number"
              fullWidth
              name={key}
              value={leadsRequest[key]}
              onChange={handleLeadsRequestChange}
            />
          ))}
          {!hasAtLeastOneLeadRequested && (
            <FormHelperText error>
              Please request at least one lead.
            </FormHelperText>
          )}
          <FormControl
            fullWidth
            margin="dense"
            error={selectedStates.length === 0}
          >
            <InputLabel>States</InputLabel>
            <Select
              label="States"
              multiple
              value={selectedStates}
              onChange={handleStatesChange}
              renderValue={(selected) => selected.join(", ")}
            >
              {statesList.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  <Checkbox
                    checked={selectedStates.indexOf(state.value) > -1}
                  />
                  <ListItemText primary={state.label} />
                </MenuItem>
              ))}
            </Select>
            {selectedStates.length === 0 && (
              <FormHelperText>Please select at least one state.</FormHelperText>
            )}
          </FormControl>
          <Divider sx={{ marginY: 2 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Total Tokens Used:</Typography>
            <Typography variant="h6" color="primary">
              {totalTokens}
            </Typography>
          </Stack>
          {error && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClaimDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleClaimLeads}
            color="primary"
            disabled={!isFormValid || isClaiming}
          >
            {isClaiming ? <CircularProgress size={24} /> : "Claim Leads"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPurchaseDialog}
        onClose={() => setOpenPurchaseDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Purchase Overrun Tokens</DialogTitle>
        <DialogContent>
          {!clientSecret ? (
            <>
              <DialogContentText>
                Purchase tokens to claim overrun leads. Each token allows you to
                claim one lead. Minimum order quantity is{" "}
                {overrunProducts[selectedTab]?.order_minimum} tokens.
              </DialogContentText>
              <TextField
                margin="dense"
                label="Number of Tokens"
                type="number"
                fullWidth
                value={purchaseTokens}
                onChange={(e) =>
                  setPurchaseTokens(
                    Math.max(
                      overrunProducts[selectedTab]?.order_minimum || 1,
                      parseInt(e.target.value) || 0
                    )
                  )
                }
                sx={{ mb: 2 }}
                inputProps={{
                  min: overrunProducts[selectedTab]?.order_minimum || 1,
                }}
                helperText={`Minimum order: ${
                  overrunProducts[selectedTab]?.order_minimum || 1
                } tokens`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptNoRefunds}
                    onChange={(e) => setAcceptNoRefunds(e.target.checked)}
                  />
                }
                label="I understand that overrun token purchases are non-refundable"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptNoReplacements}
                    onChange={(e) => setAcceptNoReplacements(e.target.checked)}
                  />
                }
                label="I understand that overrun leads cannot be replaced"
              />
              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
              <DialogActions>
                <Button onClick={() => setOpenPurchaseDialog(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={handlePurchaseTokens}
                  variant="contained"
                  disabled={
                    isLoadingStripe || !acceptNoRefunds || !acceptNoReplacements
                  }
                >
                  {isLoadingStripe ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Proceed to Payment"
                  )}
                </Button>
              </DialogActions>
            </>
          ) : (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <OverrunCheckoutForm
                orderDetails={orderDetails}
                orderId={orderDetails.orderId}
              />
            </Elements>
          )}
        </DialogContent>
      </Dialog>

      {/* Completion Dialog */}
      <Dialog
        open={completionDialogOpen}
        onClose={() => setCompletionDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 2,
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", pb: 3 }}>
          <CheckCircleOutlineIcon
            sx={{
              fontSize: 48,
              color: "success.main",
              mb: 1,
            }}
          />
          <Typography variant="h5" component="div">
            Leads Successfully Claimed!
          </Typography>
        </DialogTitle>

        <DialogContent>
          {claimResponse && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "background.default",
                    borderRadius: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography variant="h4" color="primary">
                          {claimResponse.leads_claimed}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Leads Claimed
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography variant="h4" color="primary">
                          #{claimResponse.order_id}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Order Number
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography variant="h4" color="primary">
                          {claimResponse.total_tokens_used}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Tokens Used
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 2, display: "flex", alignItems: "center" }}
                  >
                    <TimelineIcon sx={{ mr: 1 }} />
                    Lead Age Distribution
                  </Typography>
                  <Box sx={{ pl: 4 }}>
                    {Object.entries(claimResponse.token_usage).map(
                      ([age, count]) => (
                        <Box
                          key={age}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mb: 1,
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            {age}
                          </Typography>
                          <Chip
                            label={count}
                            size="small"
                            color={count > 0 ? "primary" : "default"}
                            sx={{ minWidth: 60 }}
                          />
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 2, display: "flex", alignItems: "center" }}
                  >
                    <LocationOnIcon sx={{ mr: 1 }} />
                    States with Leads
                  </Typography>
                  <Box sx={{ pl: 4 }}>
                    {Object.entries(claimResponse.leads_by_state).map(
                      ([state, ages]) => (
                        <Box
                          key={state}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mb: 1,
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            {state}
                          </Typography>
                          <Chip
                            label={Object.values(ages).reduce(
                              (sum, count) => sum + count,
                              0
                            )}
                            size="small"
                            color={
                              Object.values(ages).reduce(
                                (sum, count) => sum + count,
                                0
                              ) > 0
                                ? "primary"
                                : "default"
                            }
                            sx={{ minWidth: 60 }}
                          />
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", pt: 3 }}>
          <Button
            variant="contained"
            onClick={() => {
              setCompletionDialogOpen(false);
              navigate("/leads");
            }}
            startIcon={<LocalOfferIcon />}
            size="large"
          >
            View My Leads
          </Button>
          <Button
            onClick={() => setCompletionDialogOpen(false)}
            color="inherit"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {renderStripeDialog()}
    </div>
  );
};

export default ClaimOverrunLeads;
