import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Container,
  Snackbar,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  Settings,
  PhoneCall,
  BrainCircuit,
  GitMerge,
  RefreshCw,
  History,
} from "lucide-react";
import decode from "../../../general/util/jwtDecode";
import customFetch from "../../../general/auth/customFetch";

export default function VoiceAIDashboard() {
  const [integrations, setIntegrations] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSyncingSubaccounts, setSyncingSubaccounts] = useState(false);
  const [isSyncingAssistants, setSyncingAssistants] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const currentUser = decode();

  useEffect(() => {
    loadConfig();
  }, [currentUser.tenant_id]);

  const loadConfig = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/voice-ai/status`
      );
      if (response.ok) {
        const data = await response.json();
        setIntegrations(data.status);
      }
    } catch (error) {
      console.error("Failed to load voice AI status:", error);
      showSnackbar("Failed to load Voice AI status", "error");
    }
  };

  const handleSync = async (type, integrationId) => {
    const endpoint =
      type === "subaccounts"
        ? "sync-synthflow-subaccounts"
        : "sync-synthflow-assistants";
    const setLoading =
      type === "subaccounts" ? setSyncingSubaccounts : setSyncingAssistants;

    setLoading(true);
    try {
      const response = await customFetch(
        `/v1/cron/${endpoint}?integration_id=${integrationId}`
      );

      if (response.ok) {
        showSnackbar(`Successfully synced ${type}`, "success");
        loadConfig();
      } else {
        throw new Error(`Failed to sync ${type}`);
      }
    } catch (error) {
      console.error(`Sync failed for ${type}:`, error);
      showSnackbar(`Failed to sync ${type}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage("");
  };

  const getCards = (integration) => [
    {
      title: "Configuration",
      icon: <Settings className="w-8 h-8" />,
      status: integration.api_key ? "Configured" : "Not Configured",
      isComplete: integration.api_key,
      action: "/admin/integrations",
      description: "Setup your Synthflow API connection",
    },
    {
      title: "Sub Accounts",
      icon: <GitMerge className="w-8 h-8" />,
      status:
        integration.subaccounts.total > 0
          ? `${integration.subaccounts.active} Active`
          : "Not Configured",
      isComplete: integration.subaccounts.total > 0,
      action: "/admin/voice-ai/subaccounts",
      description: "Manage your Synthflow sub accounts",
      syncButton: {
        loading: isSyncingSubaccounts,
        onClick: () => handleSync("subaccounts", integration.integration_id),
      },
    },
    {
      title: "Assistants",
      icon: <BrainCircuit className="w-8 h-8" />,
      status:
        integration.assistants.total > 0
          ? `${integration.assistants.active} Active`
          : "Not Configured",
      isComplete: integration.assistants.total > 0,
      action: "/admin/voice-ai/assistants",
      description: "Configure AI assistants for your calls",
      syncButton: {
        loading: isSyncingAssistants,
        onClick: () => handleSync("assistants", integration.integration_id),
      },
    },
    {
      title: "Call Slots",
      icon: <PhoneCall className="w-8 h-8" />,
      status: `${integration.call_slots.active}/${integration.call_slots.total} Active`,
      isComplete: integration.call_slots.total > 0,
      action: "/admin/voice-ai/calls",
      description: "Monitor and manage active calls",
    },
    {
      title: "Call History",
      icon: <History className="w-8 h-8" />,
      status: "View History",
      isComplete: true,
      action: "/admin/voice-ai/history",
      description: "View historical call data and analytics",
    },
  ];

  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{ color: "primary.main", mb: 2 }}
      >
        Voice AI Management
      </Typography>

      {integrations.length === 0 && (
        <Alert severity="info" sx={{ mb: 3 }}>
          Get started by configuring your Synthflow API connection
        </Alert>
      )}

      {integrations.map((integration, idx) => (
        <Box key={integration.integration_id} sx={{ mb: 4 }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ color: "primary.main", mb: 2 }}
          >
            {integration.name} (#{integration.integration_id})
          </Typography>

          <Grid container spacing={3}>
            {getCards(integration).map((card, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    height: "230px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {card.icon}
                        {card.isComplete ? (
                          <Alert severity="success" sx={{ py: 0 }}>
                            {card.status}
                          </Alert>
                        ) : (
                          <Alert severity="warning" sx={{ py: 0 }}>
                            {card.status}
                          </Alert>
                        )}
                      </Box>
                      <Typography variant="h6" gutterBottom>
                        {card.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {card.description}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() =>
                          (window.location.href = `${card.action}/${integration.integration_id}`)
                        }
                      >
                        {card.isComplete ? "Manage" : "Configure"}
                      </Button>
                      {card.syncButton && (
                        <Tooltip
                          title={`Sync ${card.title.toLowerCase()} from Synthflow API to update latest configuration`}
                        >
                          <Button
                            variant="outlined"
                            disabled={card.syncButton.loading}
                            onClick={card.syncButton.onClick}
                            sx={{ minWidth: "auto" }}
                          >
                            <RefreshCw
                              className={
                                card.syncButton.loading ? "animate-spin" : ""
                              }
                            />
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {idx < integrations.length - 1 && <Divider sx={{ my: 4 }} />}
        </Box>
      ))}

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={snackbarSeverity} onClose={handleCloseSnackbar}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
