import React, { useEffect, useState, useCallback } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  Stack,
  Link,
  Chip,
  LinearProgress,
  Divider,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import customFetch from "../../../../general/auth/customFetch";
import { Elements } from "@stripe/react-stripe-js";
import { getStripe } from "../../../../general/util/stripe";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const POLLING_INTERVAL = 3000; // 3 seconds
const MAX_POLLING_ATTEMPTS = 20; // Maximum 1 minute of polling

function OverrunOrderCompletionContent() {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectStatus, setRedirectStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [pollingCount, setPollingCount] = useState(0);
  const [isPolling, setIsPolling] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const fetchOrderDetails = useCallback(
    async (paymentIntentId) => {
      try {
        const response = await customFetch(
          `/v1/orders/overruns/payment-intent/${paymentIntentId}`
        );
        const data = await response.json();
        setOrderDetails(data);

        // Stop polling if order is fulfilled or payment is final
        if (
          data.order_status === 'fulfilled' ||
          data.stripe_charge_id || 
          pollingCount >= MAX_POLLING_ATTEMPTS
        ) {
          setIsPolling(false);
          setLoading(false);
          return true; // Stop polling
        }

        return false; // Continue polling
      } catch (error) {
        console.error("Error fetching order details:", error);
        return true; // Stop polling on error
      }
    },
    [pollingCount]
  );

  useEffect(() => {
    let pollingTimeout;

    const startPolling = async (paymentIntentId) => {
      setIsPolling(true);
      const shouldStopPolling = await fetchOrderDetails(paymentIntentId);

      if (!shouldStopPolling && pollingCount < MAX_POLLING_ATTEMPTS) {
        setPollingCount((prev) => prev + 1);
        pollingTimeout = setTimeout(
          () => startPolling(paymentIntentId),
          POLLING_INTERVAL
        );
      } else {
        setIsPolling(false);
        setLoading(false);
      }
    };

    if (!stripe) {
      return;
    }

    const clientSecret = searchParams.get("payment_intent_client_secret");
    const paymentIntentId = searchParams.get("payment_intent");
    const redirectStatus = searchParams.get("redirect_status");

    setRedirectStatus(redirectStatus);

    if (!clientSecret || !paymentIntentId) {
      setMessage("Payment information is missing. Please contact support.");
      setLoading(false);
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setPaymentStatus(paymentIntent.status);

      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment successful!");
          startPolling(paymentIntentId);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          startPolling(paymentIntentId);
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          setLoading(false);
          break;
        default:
          setMessage("Something went wrong with your payment.");
          setLoading(false);
          break;
      }
    });

    return () => {
      if (pollingTimeout) {
        clearTimeout(pollingTimeout);
      }
    };
  }, [stripe, searchParams, fetchOrderDetails, pollingCount]);

  const getStatusIcon = () => {
    if (loading || isPolling) return <CircularProgress />;
    if (paymentStatus === "succeeded") return <CheckCircleIcon color="success" sx={{ fontSize: 40 }} />;
    if (paymentStatus === "processing") return <AccessTimeIcon color="warning" sx={{ fontSize: 40 }} />;
    return <ErrorIcon color="error" sx={{ fontSize: 40 }} />;
  };

  const getStatusColor = () => {
    if (paymentStatus === "succeeded") return "success.main";
    if (paymentStatus === "processing") return "warning.main";
    return "error.main";
  };

  const renderStatusChip = (type, status) => {
    let color = "default";
    let statusText = status;
    let icon = null;

    switch (status) {
      case "succeeded":
      case "fulfilled":
        color = "success";
        icon = <CheckCircleIcon fontSize="small" />;
        break;
      case "failed":
      case "canceled":
        color = "error";
        icon = <ErrorIcon fontSize="small" />;
        break;
      case "processing":
      case "pending":
        color = "warning";
        icon = <AccessTimeIcon fontSize="small" />;
        break;
      default:
        color = "default";
    }

    return (
      <Chip 
        icon={icon}
        label={`${type}: ${statusText.toUpperCase()}`}
        color={color}
        sx={{ fontWeight: 'medium' }}
      />
    );
  };

  if (!stripe) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
      <Card elevation={3}>
        <CardContent>
          <Stack spacing={3} alignItems="center">
            {getStatusIcon()}
            
            <Typography variant="h4" component="h1" gutterBottom align="center" color={getStatusColor()}>
              {message}
            </Typography>

            {(loading || isPolling) && (
              <Box sx={{ width: "100%", mt: 2 }}>
                <LinearProgress />
              </Box>
            )}

            {orderDetails && (
              <>
                <Divider sx={{ width: "100%", my: 2 }} />
                
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <ReceiptLongIcon sx={{ mr: 1 }} />
                      <Typography variant="h6" gutterBottom>
                        Order Details
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                      {renderStatusChip("Payment", paymentStatus)}
                      {renderStatusChip("Order", orderDetails.order_status)}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Order Information
                        </Typography>
                        <Stack spacing={2}>
                          <Box>
                            <Typography variant="body2" color="text.secondary">
                              Order ID
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                              {orderDetails.id}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body2" color="text.secondary">
                              Order Date
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                              {new Date(orderDetails.created_time).toLocaleDateString()}
                            </Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Purchase Details
                        </Typography>
                        <Stack spacing={2}>
                          <Box>
                            <Typography variant="body2" color="text.secondary">
                              Tokens Ordered
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                              {orderDetails.total_tokens_ordered}
                            </Typography>
                          </Box>
                          {orderDetails.stripe_payment_intent_id && (
                            <Box>
                              <Typography variant="body2" color="text.secondary">
                                Payment Reference
                              </Typography>
                              <Typography variant="body1" fontWeight="medium" sx={{ wordBreak: "break-all" }}>
                                {orderDetails.stripe_payment_intent_id}
                              </Typography>
                            </Box>
                          )}
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </>
            )}

            {orderDetails && orderDetails.order_status === 'fulfilled' && (
              <Box sx={{ width: '100%', mt: 4 }}>
                <Card variant="outlined" sx={{ bgcolor: 'primary.light', color: 'primary.contrastText' }}>
                  <CardContent>
                    <Stack spacing={3} alignItems="center">
                      <Typography variant="h6" align="center">
                        🎉 Your tokens are ready to use!
                      </Typography>
                      <Typography variant="body1" align="center">
                        You can now use your {orderDetails.total_tokens_ordered} tokens to claim overrun leads.
                        Each lead will cost 1 token.
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => navigate("/overruns")}
                        sx={{ 
                          minWidth: 200,
                          bgcolor: 'common.white',
                          color: 'primary.main',
                          '&:hover': {
                            bgcolor: 'grey.100'
                          }
                        }}
                      >
                        Start Claiming Leads
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            )}

            {orderDetails && orderDetails.order_status !== 'fulfilled' && (
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate("/overruns")}
                >
                  Return to Overrun Leads
                </Button>
              </Box>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

// Wrapper component that provides Stripe context
function OverrunOrderCompletion() {
  const [searchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const paymentIntentId = searchParams.get("payment_intent");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!paymentIntentId) {
        setLoading(false);
        return;
      }

      try {
        const response = await customFetch(`/v1/orders/overruns/payment-intent/${paymentIntentId}`);
        const data = await response.json();
        setOrderDetails(data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [paymentIntentId]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!orderDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography color="error">
          Unable to load order details. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Elements stripe={getStripe(orderDetails.product_id)}>
      <OverrunOrderCompletionContent />
    </Elements>
  );
}

export default OverrunOrderCompletion;