// client/src/pages/admin/voice-ai/components/VoiceAIBreadcrumbs.js
import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Home, ChevronRight } from "lucide-react";
import { Link as RouterLink } from "react-router-dom";

const VoiceAIBreadcrumbs = ({ currentPage, sx = {} }) => {
  // Custom styled link component that combines MUI styling with React Router
  const StyledLink = ({ to, children, ...props }) => (
    <RouterLink
      to={to}
      style={{
        textDecoration: "none",
        color: "inherit",
        display: "flex",
        alignItems: "center",
      }}
      {...props}
    >
      <Typography
        sx={{
          "&:hover": {
            color: "primary.main",
            textDecoration: "underline",
          },
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
      </Typography>
    </RouterLink>
  );

  return (
    <Breadcrumbs separator={<ChevronRight size={16} />} sx={{ mb: 3, ...sx }}>
      <StyledLink to="/admin">
        <Home size={16} style={{ marginRight: "4px" }} />
        Dashboard
      </StyledLink>

      <StyledLink to="/admin/voice-ai">Voice AI</StyledLink>

      <Typography
        color="text.secondary"
        sx={{
          fontWeight: 500,
        }}
      >
        {currentPage}
      </Typography>
    </Breadcrumbs>
  );
};

export default VoiceAIBreadcrumbs;
