import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";
import {
  PhoneCall,
  PhoneOff,
  RefreshCw,
  Clock,
  AlertTriangle,
} from "lucide-react";
import decode from "../../../../general/util/jwtDecode";
import customFetch from "../../../../general/auth/customFetch";
import { LicenseInfo } from "@mui/x-license";
import VoiceAIBreadcrumbs from "../components/VoiceAIBreadcrumbs";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const CallSlotsDashboard = () => {
  const { integrationId } = useParams();
  const currentUser = decode();
  const tenant_id = currentUser.tenant_id;
  const [slots, setSlots] = useState([]);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    available: 0,
    error: 0,
  });
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeInterval, setTimeInterval] = useState('hour');
  const [timeRange, setTimeRange] = useState({
    from: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
    to: new Date().toISOString(),
  });
  const [chartData, setChartData] = useState([]);
  const [loadingCharts, setLoadingCharts] = useState(true);

  // Load slots data
  const loadSlots = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${tenant_id}/voice-ai/call-slots?integration_id=${integrationId}`
      );
      if (!response.ok) throw new Error("Failed to load call slots");
      const data = await response.json();

      setSlots(data.slots);
      setStats({
        total: data.stats.total,
        active: data.stats.active,
        available: data.stats.available,
        error: data.stats.error,
      });
      setLoading(false);
    } catch (err) {
      setError("Failed to load call slots data");
      setLoading(false);
    }
  };

  const handleIntervalChange = (event) => {
    const newInterval = event.target.value;
    setTimeInterval(newInterval);
    
    // Update time range based on interval
    const now = new Date();
    let fromDate;
    
    switch (newInterval) {
      case 'hour':
        fromDate = new Date(now.getTime() - 24 * 60 * 60 * 1000); // Last 24 hours
        break;
      case 'day':
        fromDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // Last 7 days
        break;
      case 'week':
        fromDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // Last 30 days
        break;
      default:
        fromDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    }
    
    setTimeRange({
      from: fromDate.toISOString(),
      to: now.toISOString(),
    });
  };

  const loadTimeSeriesData = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${tenant_id}/voice-ai/call-stats?from_date=${timeRange.from}&to_date=${timeRange.to}&interval=${timeInterval}${integrationId ? `&integration_id=${integrationId}` : ''}`
      );

      if (!response.ok) throw new Error("Failed to load chart data");
      const data = await response.json();

      // If no data, provide empty placeholder data for the charts
      if (!data.data || data.data.length === 0) {
        const emptyDataPoints = timeInterval === 'hour' ? 24 : timeInterval === 'day' ? 7 : 30;
        const emptyData = Array.from({ length: emptyDataPoints }, (_, i) => ({
          time: new Date(Date.now() - (emptyDataPoints - 1 - i) * (timeInterval === 'hour' ? 60 * 60 * 1000 : timeInterval === 'day' ? 24 * 60 * 60 * 1000 : 7 * 24 * 60 * 60 * 1000)).toISOString(),
          totalCalls: 0,
          completedCalls: 0,
          failedCalls: 0,
          successRate: 0,
          avgDuration: 0,
          bookings: 0,
        }));
        setChartData(emptyData);
      } else {
        setChartData(data.data);
      }

      setLoadingCharts(false);
    } catch (err) {
      console.error("Error loading time series data:", err);
      setLoadingCharts(false);
      setChartData([]);
    }
  };

  // Update useEffect
  useEffect(() => {
    if (!integrationId || !tenant_id) return;
    loadSlots();
    loadTimeSeriesData();

    // Refresh data every 5 minutes
    const interval = setInterval(() => {
      loadSlots();
      loadTimeSeriesData();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [integrationId, tenant_id, timeRange]);

  // Handle slot actions
  const handleReset = async (slotId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${tenant_id}/voice-ai/call-slots/${slotId}/reset`,
        { method: "POST" }
      );
      if (!response.ok) throw new Error("Failed to reset slot");
      loadSlots(); // Reload data
    } catch (err) {
      console.error("Error resetting slot:", err);
      setError("Failed to reset call slot");
    }
  };

  const handleHangup = async (slotId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${tenant_id}/voice-ai/call-slots/${slotId}/hangup`,
        { method: "POST" }
      );
      if (!response.ok) throw new Error("Failed to hangup call");
      loadSlots(); // Reload data
    } catch (err) {
      console.error("Error hanging up call:", err);
      setError("Failed to hang up call");
    }
  };

  // DataGrid columns
  const columns = [
    { field: "id", headerName: "Slot ID", width: 90 },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={
            params.value === "available"
              ? "success"
              : params.value === "in_use"
              ? "primary"
              : "error"
          }
          variant="outlined"
          size="small"
        />
      ),
    },
    {
      field: "ghl_location_name",
      headerName: "GHL Location",
      flex: 1.5,
      valueGetter: (params, row) => row.ghl_location_name || "-",
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      flex: .8,
      valueGetter: (params, row) => row.contact_name || "-",
    },
    {
      field: "contact_phone",
      headerName: "Contact Phone",
      flex: 1,
      valueGetter: (params, row) => row.contact_phone || "-",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Reset Slot">
            <IconButton
              size="small"
              onClick={() => handleReset(params.row.id)}
              color="warning"
            >
              <RefreshCw size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Hangup Call (Coming Soon)">
            <span>
              <IconButton
                size="small"
                disabled={true}
                sx={{ color: "grey.400" }}
              >
                <PhoneOff size={20} />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const renderCharts = () => (
    <Grid container spacing={3} sx={{ mb: 4 }}>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Call Statistics Over Time
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData} key={timeInterval}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="time"
                tickFormatter={(time) => {
                  const date = new Date(time);
                  return timeInterval === 'hour' 
                    ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                    : timeInterval === 'day'
                    ? date.toLocaleDateString([], { month: 'short', day: 'numeric' })
                    : date.toLocaleDateString([], { month: 'short', day: 'numeric' });
                }}
                interval="preserveStartEnd"
              />
              <YAxis 
                yAxisId="left"
                domain={[0, 'auto']}
                allowDataOverflow={false}
              />
              <YAxis 
                yAxisId="right"
                orientation="right"
                domain={[0, 'auto']}
                allowDataOverflow={false}
              />
              <RechartsTooltip
                labelFormatter={(label) => {
                  const date = new Date(label);
                  return timeInterval === 'hour'
                    ? date.toLocaleString([], { 
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })
                    : date.toLocaleDateString([], {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      });
                }}
                formatter={(value, name) => {
                  if (name === "Average Duration") {
                    return [`${value} sec`, name];
                  }
                  return [value, name];
                }}
              />
              <Line
                type="monotone"
                dataKey="totalCalls"
                name="Total Calls"
                stroke="#2196f3"
                strokeWidth={2}
                yAxisId="left"
              />
              <Line
                type="monotone"
                dataKey="completedCalls"
                name="Completed"
                stroke="#4caf50"
                strokeWidth={2}
                yAxisId="left"
              />
              <Line
                type="monotone"
                dataKey="failedCalls"
                name="Failed"
                stroke="#f44336"
                strokeWidth={2}
                yAxisId="left"
              />
              <Line
                type="monotone"
                dataKey="avgDuration"
                name="Average Duration"
                stroke="#ff9800"
                strokeWidth={2}
                yAxisId="right"
                dot={true}
              />
            </LineChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Success Metrics
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData} key={timeInterval}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="time"
                tickFormatter={(time) => {
                  const date = new Date(time);
                  return timeInterval === 'hour' 
                    ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                    : timeInterval === 'day'
                    ? date.toLocaleDateString([], { month: 'short', day: 'numeric' })
                    : date.toLocaleDateString([], { month: 'short', day: 'numeric' });
                }}
                interval="preserveStartEnd"
              />
              <YAxis 
                yAxisId="left"
                domain={[0, 100]}
                allowDataOverflow={false}
              />
              <YAxis 
                yAxisId="right"
                orientation="right"
                domain={[0, 'auto']}
                allowDataOverflow={false}
              />
              <RechartsTooltip
                labelFormatter={(label) => {
                  const date = new Date(label);
                  return timeInterval === 'hour'
                    ? date.toLocaleString([], { 
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })
                    : date.toLocaleDateString([], {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      });
                }}
                formatter={(value, name) => {
                  if (name === "Success Rate") {
                    return [`${value}%`, name];
                  }
                  return [value, name];
                }}
              />
              <Line
                type="monotone"
                dataKey="successRate"
                name="Success Rate"
                stroke="#4caf50"
                strokeWidth={2}
                yAxisId="left"
              />
              <Line
                type="monotone"
                dataKey="bookings"
                name="Bookings"
                stroke="#9c27b0"
                strokeWidth={2}
                yAxisId="right"
                dot={true}
              />
            </LineChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
    </Grid>
  );
  return (
    <Container maxWidth="xl">
      <VoiceAIBreadcrumbs currentPage="Call Slots Dashboard" />
      <Typography
        variant="h4"
        gutterBottom
        sx={{ mb: 4, color: "primary.main" }}
      >
        Call Slots Dashboard
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Time Interval Selector */}
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="time-interval-label">Time Interval</InputLabel>
          <Select
            labelId="time-interval-label"
            value={timeInterval}
            label="Time Interval"
            onChange={handleIntervalChange}
          >
            <MenuItem value="hour">Last 24 Hours</MenuItem>
            <MenuItem value="day">Last 7 Days</MenuItem>
            <MenuItem value="week">Last 30 Days</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <PhoneCall size={24} color="primary" />
                <Typography variant="h6" sx={{ ml: 1 }}>
                  Total Slots
                </Typography>
              </Box>
              <Typography variant="h4">{stats.total}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Clock size={24} color="success" />
                <Typography variant="h6" sx={{ ml: 1 }}>
                  Active Calls
                </Typography>
              </Box>
              <Typography variant="h4">{stats.active}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <PhoneOff size={24} color="info" />
                <Typography variant="h6" sx={{ ml: 1 }}>
                  Available
                </Typography>
              </Box>
              <Typography variant="h4">{stats.available}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <AlertTriangle size={24} color="error" />
                <Typography variant="h6" sx={{ ml: 1 }}>
                  Errors
                </Typography>
              </Box>
              <Typography variant="h4">{stats.error}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {loadingCharts ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        renderCharts()
      )}

      {/* Slots Table */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <DataGridPro
          rows={slots}
          columns={columns}
          autoHeight
          loading={loading}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
            sorting: {
              sortModel: [{ field: 'id', sort: 'asc' }],
            },
          }}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
          error={error}
        />
      </Paper>
    </Container>
  );
};

export default CallSlotsDashboard;
