import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Alert,
  CircularProgress,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Stack,
  Tooltip,
} from "@mui/material";
import decode from "../../../../general/util/jwtDecode.js";
import customFetch from "../../../../general/auth/customFetch.js";
import SvgIcon from "@mui/material/SvgIcon";

const StripeIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M13.479 9.883c-1.626-.604-2.512-1.067-2.512-1.803 0-.622.511-1.024 1.402-1.024 1.604 0 3.237.767 4.378 1.164l.655-4.034c-.897-.384-2.74-1.024-5.224-1.024-1.803 0-3.307.473-4.403 1.338-1.13.897-1.724 2.175-1.724 3.746 0 2.827 1.724 4.034 4.544 5.058 1.803.622 2.416 1.067 2.416 1.761 0 .664-.589 1.109-1.637 1.109-1.313 0-3.485-.675-4.925-1.569l-.655 4.127c1.234.737 3.526 1.493 5.903 1.493 1.93 0 3.541-.462 4.67-1.338 1.192-.918 1.82-2.282 1.82-3.961-.011-2.891-1.766-4.137-4.708-5.224z" />
  </SvgIcon>
);

const PAYMENT_METHODS = ["card", "affirm"];
const CURRENCIES = ["usd", "eur", "gbp", "aud", "cad"];
const PAYOUT_INTERVALS = ["daily", "weekly", "monthly"];

export const StripeEditDialog = ({
  open,
  onClose,
  integration,
  onUpdateSuccess,
}) => {
  const [formData, setFormData] = useState({
    publishable_key: "",
    secret_key: "",
    automatic_tax: false,
    payment_methods: ["card"],
    default_currency: "usd",
    statement_descriptor: "",
    statement_descriptor_suffix: "",
    business_profile: {
      support_email: "",
      support_phone: "",
      support_url: "",
    },
    payout_schedule: {
      interval: "daily",
      delay_days: 2,
    },
    metadata: {},
  });
  
  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentUser = decode();

  useEffect(() => {
    if (integration?.data) {
      setFormData({
        publishable_key: integration.data.publishable_key || "",
        secret_key: integration.data.secret_key || "",
        automatic_tax: integration.data.settings?.automatic_tax || false,
        payment_methods: integration.data.settings?.payment_methods || ["card"],
        default_currency: integration.data.settings?.default_currency || "usd",
        statement_descriptor: integration.data.settings?.statement_descriptor || "",
        statement_descriptor_suffix: integration.data.settings?.statement_descriptor_suffix || "",
        business_profile: integration.data.settings?.business_profile || {
          support_email: "",
          support_phone: "",
          support_url: "",
        },
        payout_schedule: integration.data.settings?.payout_schedule || {
          interval: "daily",
          delay_days: 2,
        },
        metadata: integration.data.settings?.metadata || {},
      });
    }
  }, [integration]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/integrations/stripe/${integration.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update Stripe integration');
      }

      const data = await response.json();
      onUpdateSuccess(data);
      onClose();
    } catch (err) {
      console.error("Error updating Stripe integration:", err);
      setError({
        severity: "error",
        message: err.message || "Failed to update Stripe integration"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleValidate = async () => {
    setIsValidating(true);
    setError(null);

    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/integrations/stripe/validate`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ secret_key: formData.secret_key }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to validate API key');
      }

      const data = await response.json();
      if (data.valid) {
        setError({ severity: "success", message: "API key is valid" });
      } else {
        setError({ severity: "error", message: data.message || "Invalid API key" });
      }
    } catch (err) {
      console.error("Error validating API key:", err);
      setError({ 
        severity: "error", 
        message: err.message || "Failed to validate API key" 
      });
    } finally {
      setIsValidating(false);
    }
  };

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleBusinessProfileChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      business_profile: {
        ...prev.business_profile,
        [field]: value,
      },
    }));
  };

  const handlePayoutScheduleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      payout_schedule: {
        ...prev.payout_schedule,
        [field]: value,
      },
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <StripeIcon /> Configure Stripe Integration
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
            API Key Management
          </Typography>
          <TextField
            fullWidth
            label="Publishable Key"
            type="password"
            value={formData.publishable_key}
            onChange={(e) => handleChange("publishable_key", e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Secret Key"
            type="password"
            value={formData.secret_key}
            onChange={(e) => handleChange("secret_key", e.target.value)}
            margin="normal"
          />
          <Button
            onClick={handleValidate}
            disabled={isValidating || !formData.secret_key}
            sx={{ mt: 1 }}
          >
            {isValidating ? <CircularProgress size={24} /> : "Validate API Key"}
          </Button>

          {error && (
            <Alert severity={error.severity} sx={{ mt: 2 }}>
              {error.message}
            </Alert>
          )}

          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
            Payment Configuration
          </Typography>

          <FormControl fullWidth margin="normal">
            <InputLabel>Payment Methods</InputLabel>
            <Select
              multiple
              label="Payment Methods"
              value={formData.payment_methods}
              onChange={(e) => handleChange("payment_methods", e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {PAYMENT_METHODS.map((method) => (
                <MenuItem key={method} value={method}>
                  {method}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Default Currency</InputLabel>
            <Select
              value={formData.default_currency}
              label="Default Currency"
              onChange={(e) => handleChange("default_currency", e.target.value)}
            >
              {CURRENCIES.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Statement Descriptor"
            value={formData.statement_descriptor}
            onChange={(e) =>
              handleChange("statement_descriptor", e.target.value)
            }
            margin="normal"
            helperText="Appears on customer's credit card statement (max 22 characters)"
          />

          <TextField
            fullWidth
            label="Statement Descriptor Suffix"
            value={formData.statement_descriptor_suffix}
            onChange={(e) =>
              handleChange("statement_descriptor_suffix", e.target.value)
            }
            margin="normal"
            helperText="Additional information after your business name (max 12 characters)"
          />

          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
            Business Profile
          </Typography>

          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Support Email"
              type="email"
              value={formData.business_profile.support_email}
              onChange={(e) =>
                handleBusinessProfileChange("support_email", e.target.value)
              }
            />

            <TextField
              fullWidth
              label="Support Phone"
              value={formData.business_profile.support_phone}
              onChange={(e) =>
                handleBusinessProfileChange("support_phone", e.target.value)
              }
            />

            <TextField
              fullWidth
              label="Support URL"
              value={formData.business_profile.support_url}
              onChange={(e) =>
                handleBusinessProfileChange("support_url", e.target.value)
              }
            />
          </Stack>

          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
            Payout Schedule
            <Tooltip title="Payout schedule configuration will be supported in a future release" arrow>
              <Box component="span" sx={{ ml: 1, color: 'text.secondary', cursor: 'help' }}>
                (Coming Soon)
              </Box>
            </Tooltip>
          </Typography>

          <Stack spacing={2}>
            <FormControl fullWidth disabled>
              <InputLabel>Interval</InputLabel>
              <Select
                value={formData.payout_schedule.interval}
                label="Interval"
                onChange={(e) =>
                  handlePayoutScheduleChange("interval", e.target.value)
                }
              >
                {PAYOUT_INTERVALS.map((interval) => (
                  <MenuItem key={interval} value={interval}>
                    {interval.charAt(0).toUpperCase() + interval.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              type="number"
              label="Delay Days"
              value={formData.payout_schedule.delay_days}
              onChange={(e) =>
                handlePayoutScheduleChange("delay_days", parseInt(e.target.value))
              }
              inputProps={{ min: 0 }}
              disabled
            />
          </Stack>

          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
            Tax Configuration
            <Tooltip title="Automatic tax calculation will be supported in a future release" arrow>
              <Box component="span" sx={{ ml: 1, color: 'text.secondary', cursor: 'help' }}>
                (Coming Soon)
              </Box>
            </Tooltip>
          </Typography>

          <FormControlLabel
            control={
              <Tooltip title="Automatic tax calculation will be supported in a future release" arrow>
                <Box>
                  <Switch
                    checked={formData.automatic_tax}
                    onChange={(e) =>
                      handleChange("automatic_tax", e.target.checked)
                    }
                    disabled
                  />
                </Box>
              </Tooltip>
            }
            disabled
            label="Enable Automatic Tax"
          />

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isSubmitting || !formData.secret_key}
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Save Changes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
