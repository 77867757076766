import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import decode from "../../../../general/util/jwtDecode.js";
import customFetch from "../../../../general/auth/customFetch.js";
import SvgIcon from "@mui/material/SvgIcon";

const SynthflowIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M6.86 2.05c-1.25.27-2.17.77-3.13 1.72-.76.75-1.22 1.49-1.57 2.46-.21.61-.23.75-.23 1.93 0 1.16.02 1.33.23 1.93.32.97.75 1.63 1.58 2.46.78.79 1.46 1.21 2.59 1.62.52.19.57.19 5.21.23l4.68.04.39.19c.52.26.88.78.94 1.36.07.73-.21 1.31-.86 1.71-.3.18-.37.19-2.81.23l-2.5.04-.42.22c-1.62.85-1.62 3.14 0 3.99l.42.22h2.75c3.06 0 3.24-.02 4.39-.57 1.64-.78 2.91-2.32 3.38-4.07.23-.84.21-2.49-.04-3.28-.63-2.06-2.04-3.5-4.15-4.25l-.66-.23-4.68-.04c-4.37-.04-4.7-.05-4.97-.2-1.26-.67-1.37-2.32-.2-3.04l.39-.25 5.88-.04 5.88-.04.44-.24c.76-.43 1.17-1.13 1.16-2.01-.01-.84-.42-1.54-1.15-1.94l-.44-.25-5.96-.02c-5.03 0-6.05.01-6.53.11z" />
    <path d="M4.72 18c-.93.23-1.6.99-1.73 1.96-.16 1.26.95 2.48 2.27 2.48 1.12 0 2.22-1.1 2.22-2.22 0-.6-.23-1.15-.68-1.59-.6-.59-1.32-.81-2.07-.63z" />
  </SvgIcon>
);

export const SynthflowEditDialog = ({
  open,
  onClose,
  integration,
  onUpdateSuccess,
}) => {
  const [apiKey, setApiKey] = useState("");
  const [maxConcurrentCalls, setMaxConcurrentCalls] = useState(1);
  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentUser = decode();

  useEffect(() => {
    if (integration?.data) {
      setApiKey(integration.data.api_key || "");
      setMaxConcurrentCalls(integration.data.max_concurrent_calls || 1);
    }
  }, [integration]);

  const handleValidate = async () => {
    setIsValidating(true);
    setError(null);

    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/integrations/synthflow/validate`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ api_key: apiKey }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Invalid API credentials");
      }

      const validationData = await response.json();
      if (!validationData.valid) {
        throw new Error(validationData.error || "Invalid API credentials");
      }

      return true;
    } catch (error) {
      setError(error.message);
      return false;
    } finally {
      setIsValidating(false);
    }
  };
  const handleSubmit = async () => {
    if (!integration || !integration.id) {
      setError("Invalid integration data");
      return;
    }

    if (!(await handleValidate())) {
      return;
    }

    setIsSubmitting(true);
    try {
      console.log('Integration object:', integration);
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/integrations/${integration.id}/synthflow`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            api_key: apiKey,
            max_concurrent_calls: maxConcurrentCalls,
          }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || "Failed to update integration");
      }

      const updatedData = await response.json();
      onUpdateSuccess({
        ...integration,
        data: {
          api_key: apiKey,
          max_concurrent_calls: maxConcurrentCalls
        }
      });
      onClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <SynthflowIcon />
          <Typography variant="h6">Configure Synthflow Integration</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          label="API Key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          disabled={isValidating || isSubmitting}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          type="number"
          label="Max Concurrent Calls"
          value={maxConcurrentCalls}
          onChange={(e) => setMaxConcurrentCalls(parseInt(e.target.value) || 1)}
          InputProps={{ inputProps: { min: 1, max: 10000 } }}
          disabled={isValidating || isSubmitting}
          helperText="Maximum number of concurrent calls (1-10000)"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isValidating || isSubmitting}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            isValidating || isSubmitting || !apiKey || maxConcurrentCalls < 1
          }
          startIcon={
            isValidating || isSubmitting ? <CircularProgress size={20} /> : null
          }
        >
          {isValidating ? "Validating..." : isSubmitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
