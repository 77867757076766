// BrandingContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { staticBranding } from "./general/constants/staticBranding";

const BrandingContext = createContext();

export const useBranding = () => {
  const context = useContext(BrandingContext);
  if (!context) {
    throw new Error("useBranding must be used within a BrandingProvider");
  }
  return context;
};

// Helper to safely encode URLs
const safeEncodeURL = (url) => {
  if (!url) return '';
  try {
    return encodeURI(url);
  } catch (e) {
    console.error('Error encoding URL:', e);
    return '';
  }
};

export const BrandingProvider = ({ children, themeMode = "light" }) => {
  const [branding, setBranding] = useState(() => {
    const currentDomain = window.location.hostname;
    const staticBrandingForDomain = staticBranding[currentDomain];
    
    // Ensure we have valid URLs in the static branding
    if (staticBrandingForDomain?.light?.logo) {
      return {
        light_logo_icon_url: staticBrandingForDomain.light.logoIcon,
        light_logo_url: staticBrandingForDomain.light.logo,
        dark_logo_icon_url: staticBrandingForDomain.dark?.logoIcon,
        dark_logo_url: staticBrandingForDomain.dark?.logo,
        powered_by: staticBrandingForDomain.powered_by !== false,
      };
    }
    
    return {
      powered_by: true // Default to true if no static branding
    };
  });

  // Check if dark theme is available
  const hasDarkTheme = Boolean(
    branding.dark_logo_url && branding.dark_logo_icon_url
  );

  // Get appropriate URLs based on theme mode
  const getLogo = (mode, type) => {
    const url = mode === "light" 
      ? (type === "icon" ? branding.light_logo_icon_url : branding.light_logo_url)
      : (type === "icon" ? branding.dark_logo_icon_url : branding.dark_logo_url);
    
    // Fallback to light theme if dark theme URL is missing
    if (mode === "dark" && !url) {
      return type === "icon" ? branding.light_logo_icon_url : branding.light_logo_url;
    }
    
    return safeEncodeURL(url);
  };

  const currentBranding = {
    logoIcon: getLogo(themeMode, "icon"),
    logoFull: getLogo(themeMode, "full"),
    allLogos: {
      light_logo_icon_url: safeEncodeURL(branding.light_logo_icon_url),
      light_logo_url: safeEncodeURL(branding.light_logo_url),
      dark_logo_icon_url: safeEncodeURL(branding.dark_logo_icon_url),
      dark_logo_url: safeEncodeURL(branding.dark_logo_url),
    },
    hasDarkTheme,
    poweredBy: branding.powered_by,
    getLogosForMode: (mode) => ({
      logoIcon: getLogo(mode, "icon"),
      logoFull: getLogo(mode, "full"),
    }),
  };

  useEffect(() => {
    const updateBrandingFromAPI = async () => {
      const currentDomain = window.location.hostname;
      try {
        const response = await fetch(`/v1/branding?domain=${currentDomain}`);
        if (!response.ok) throw new Error("API response was not ok");

        const brandingData = await response.json();
        if (brandingData) {
          setBranding({
            light_logo_icon_url: brandingData.light_logo_icon_url,
            light_logo_url: brandingData.light_logo_url,
            dark_logo_icon_url: brandingData.dark_logo_icon_url,
            dark_logo_url: brandingData.dark_logo_url,
            powered_by: brandingData.powered_by !== false,
          });
        }
      } catch (error) {
        console.error("Error fetching updated branding from API:", error);
      }
    };

    updateBrandingFromAPI();
  }, []);

  return (
    <BrandingContext.Provider value={currentBranding}>
      {children}
    </BrandingContext.Provider>
  );
};
