import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";

const GHLOAuthCallback = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [callbackData, setCallbackData] = useState(null);

  useEffect(() => {
    const handleOAuthCallback = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");
        const integration_id = sessionStorage.getItem("ghl_integration_id");
        const tenant_id = sessionStorage.getItem("ghl_tenant_id");

        if (!code) {
          throw new Error("Missing code parameter in OAuth callback");
        }

        if (!integration_id || !tenant_id) {
          throw new Error("Missing integration or tenant information. Please try the OAuth process again.");
        }

        const response = await customFetch(`/v1/tenantadmin/${tenant_id}/integrations/ghl/${integration_id}/oauth/callback?code=${code}`);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || "Failed to process OAuth callback");
        }

        setCallbackData(data);
        setShowConfirmation(true);
        // Clean up the stored data
        sessionStorage.removeItem("ghl_integration_id");
        sessionStorage.removeItem("ghl_tenant_id");
      } catch (err) {
        console.error("Error in GHL OAuth callback:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    handleOAuthCallback();
  }, [location]);

  const handleConfirm = () => {
    // Send success message back to opener window
    if (window.opener) {
      window.opener.postMessage(
        {
          type: "GHL_OAUTH_SUCCESS",
          data: callbackData
        },
        window.location.origin
      );
    }
    window.close();
  };

  const handleCancel = () => {
    // Send cancellation message back to opener window
    if (window.opener) {
      window.opener.postMessage(
        {
          type: "GHL_OAUTH_CANCELLED"
        },
        window.location.origin
      );
    }
    window.close();
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        gap={2}
      >
        <CircularProgress />
        <Typography variant="h6">Processing Go High Level OAuth callback...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        gap={2}
      >
        <Typography variant="h6" color="error">
          Error processing OAuth callback
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => window.close()}>
          Close Window
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        gap={2}
      >
        <Typography variant="h6">Go High Level Connected Successfully</Typography>
        <Typography variant="body1" color="textSecondary">
          Please confirm to complete the setup
        </Typography>
      </Box>

      <Dialog open={showConfirmation} onClose={handleCancel}>
        <DialogTitle>Complete Go High Level Setup</DialogTitle>
        <DialogContent>
          <Typography>
            Your Go High Level account has been connected successfully. Would you like to save this integration?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
            Save Integration
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GHLOAuthCallback;
