import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Chip,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Edit, Check, X, MapPin } from "lucide-react";
import { useParams } from "react-router-dom";
import decode from "../../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../../general/auth/customFetch";
import VoiceAIBreadcrumbs from "../components/VoiceAIBreadcrumbs";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const SynthflowSubaccounts = () => {
  const [subaccounts, setSubaccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [error, setError] = useState("");
  const [ghlLocations, setGhlLocations] = useState([]);
  const [locationMappings, setLocationMappings] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationWeight, setLocationWeight] = useState(1.0);
  const currentUser = decode();
  const { integrationId } = useParams();

  useEffect(() => {
    loadSubaccounts();
    loadGhlLocations();
  }, [integrationId]);

  const loadSubaccounts = async () => {
    try {
      const queryParams = integrationId
        ? `?integration_id=${integrationId}`
        : "";
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/synthflow/subaccounts${queryParams}`
      );
      if (response.ok) {
        const data = await response.json();
        
        // Load location mappings for each subaccount
        const subaccountsWithMappings = await Promise.all(
          data.subaccounts.map(async (subaccount) => {
            const mappingsResponse = await customFetch(
              `/v1/tenantadmin/${currentUser.tenant_id}/synthflow/location-mappings?sub_account_id=${subaccount.id}`
            );
            if (mappingsResponse.ok) {
              const mappingsData = await mappingsResponse.json();
              return {
                ...subaccount,
                locationMappings: mappingsData.location_mappings || [],
              };
            }
            return {
              ...subaccount,
              locationMappings: [],
            };
          })
        );
        
        setSubaccounts(subaccountsWithMappings);
      }
      setLoading(false);
    } catch (error) {
      console.error("Failed to load subaccounts:", error);
      setLoading(false);
    }
  };

  const loadGhlLocations = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch locations");
      }
      const data = await response.json();
      // Combine first name and last name into account_owner and add to locations
      const locationsWithOwner = data.map((location) => ({
        ...location,
        account_owner: `${location.first_name} ${location.last_name}`,
      }));
      setGhlLocations(locationsWithOwner);
    } catch (error) {
      console.error("Failed to load GHL locations:", error);
    }
  };

  const loadLocationMappings = async (subAccountId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/synthflow/location-mappings?sub_account_id=${subAccountId}`
      );
      if (response.ok) {
        const data = await response.json();
        setLocationMappings(data.location_mappings);
      }
    } catch (error) {
      console.error("Failed to load location mappings:", error);
    }
  };

  const handleEditClick = (account) => {
    setSelectedAccount(account);
    setApiKey(account.api_key || "");
    setError("");
    setDialogOpen(true);
  };

  const handleSave = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/synthflow/subaccounts/apikey`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            api_key: apiKey,
            sub_account_id: selectedAccount.synthflow_subaccount_id,
          }),
        }
      );

      if (response.ok) {
        setDialogOpen(false);
        loadSubaccounts();
        setError("");
      } else {
        const data = await response.json();
        setError(data.error || "Failed to update API key");
      }
    } catch (error) {
      setError("Failed to update API key");
    }
  };

  const handleLocationDialogOpen = (account) => {
    setSelectedAccount(account);
    loadLocationMappings(account.id);
    setLocationDialogOpen(true);
    setSelectedLocation("");
    setLocationWeight(1.0);
  };

  const handleAddLocationMapping = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/synthflow/subaccounts/${selectedAccount.id}/location-mappings`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ghl_location_id: selectedLocation,
            weight: locationWeight,
          }),
        }
      );

      if (response.ok) {
        loadLocationMappings(selectedAccount.id);
        setSelectedLocation("");
        setLocationWeight(1.0);
        loadSubaccounts(); // Refresh subaccounts data
      } else {
        const data = await response.json();
        setError(data.error || "Failed to add location mapping");
      }
    } catch (error) {
      setError("Failed to add location mapping");
    }
  };

  const handleDeleteLocationMapping = async (locationId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/synthflow/subaccounts/${selectedAccount.id}/location-mappings/${locationId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        loadLocationMappings(selectedAccount.id);
        loadSubaccounts(); // Refresh subaccounts data
      }
    } catch (error) {
      console.error("Failed to delete location mapping:", error);
    }
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const renderPermissions = (params) => {
    const permissions = params.row.permissions || {};
    return (
      <Stack direction="row" spacing={1}>
        {Object.entries(permissions).map(([key, value]) => (
          <Chip
            key={key}
            label={key}
            size="small"
            icon={
              value ? <Check className="w-4 h-4" /> : <X className="w-4 h-4" />
            }
            color={value ? "success" : "error"}
          />
        ))}
      </Stack>
    );
  };

  const renderSubscriptionStatus = (params) => {
    const subscription = params.row.subscription_data || {};
    const hasSubscription = !!subscription.stripe_subscription_id;
    return (
      <Chip
        label={hasSubscription ? "Active" : "No Subscription"}
        color={hasSubscription ? "success" : "default"}
        size="small"
      />
    );
  };

  const renderLocationMappings = (params) => {
    const mappings = params.row.locationMappings || [];
    if (mappings.length === 0) {
      return <Chip label="No Mappings" size="small" />;
    }

    return (
      <Tooltip
        title={
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle2" gutterBottom>
              Location Mappings:
            </Typography>
            <Stack spacing={1}>
              {mappings.map((mapping) => (
                <Box key={mapping.id}>
                  {mapping.location_name} - Weight: {mapping.weight}
                </Box>
              ))}
            </Stack>
          </Box>
        }
      >
        <Chip 
          label={`${mappings.length} Location${mappings.length > 1 ? 's' : ''}`}
          color="primary"
          size="small"
        />
      </Tooltip>
    );
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1.2 },
    { field: "synthflow_subaccount_id", headerName: "Subaccount ID", flex: 1 },
    { field: "agency_id", headerName: "Agency ID", flex: 1 },
    {
      field: "api_key",
      headerName: "API Key",
      flex: 1,
      renderCell: (params) => (params.value ? "••••••••" : "Not Set"),
    },
    {
      field: "subscription",
      headerName: "Subscription",
      flex: 1,
      renderCell: renderSubscriptionStatus,
    },
    {
      field: "locationMappings",
      headerName: "Location Mappings",
      width: 150,
      renderCell: renderLocationMappings,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditClick(params.row)} size="small">
            <Edit size={20} />
          </IconButton>
          <IconButton
            onClick={() => handleLocationDialogOpen(params.row)}
            size="small"
          >
            <MapPin size={20} />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Container maxWidth="xl">
      <VoiceAIBreadcrumbs
        currentPage="Sub Accounts"
        integrationId={integrationId}
      />
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{ color: "primary.main", mb: 2 }}
      >
        Synthflow Subaccounts
      </Typography>

      <Box sx={{ height: 400, width: "100%", marginTop: "20px" }}>
        <DataGridPro
          rows={subaccounts}
          columns={columns}
          loading={loading}
          pageSize={10}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          headerFilters
          pageSizeOptions={[10, 25, 50]}
          pagination
          autoHeight
          slots={{
            toolbar: CustomToolbar,
            headerFilterMenu: null,
          }}
        />
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Update API Key</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="API Key"
            type="password"
            fullWidth
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Location Mapping Dialog */}
      <Dialog
        open={locationDialogOpen}
        onClose={() => setLocationDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Manage Location Mappings for {selectedAccount?.name}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3, mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Current Mappings
            </Typography>
            <Stack spacing={1}>
              {locationMappings.map((mapping) => (
                <Box
                  key={mapping.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1,
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                  }}
                >
                  <Typography>{mapping.location_name}</Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Chip
                      label={`Weight: ${mapping.weight}`}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteLocationMapping(mapping.ghl_location_id)}
                    >
                      <X size={16} />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Add New Mapping
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Autocomplete
                value={ghlLocations.find(loc => loc.id === selectedLocation) || null}
                onChange={(e, newValue) => setSelectedLocation(newValue ? newValue.id : '')}
                options={ghlLocations
                  .filter(loc => !locationMappings.find(
                    mapping => mapping.ghl_location_id === loc.ghl_location_id
                  ))
                  .sort((a, b) => a.name.localeCompare(b.name))
                }
                getOptionLabel={(option) => `${option.name} - ${option.account_owner}`}
                renderInput={(params) => (
                  <TextField {...params} label="Search Locations" />
                )}
                fullWidth
              />
            </FormControl>

            <Box sx={{ mb: 2 }}>
              <Typography gutterBottom>Weight: {locationWeight}</Typography>
              <Slider
                value={locationWeight}
                onChange={(e, value) => setLocationWeight(value)}
                step={0.1}
                marks
                min={0}
                max={1}
                valueLabelDisplay="auto"
              />
            </Box>

            <Button
              variant="contained"
              onClick={handleAddLocationMapping}
              disabled={!selectedLocation}
              fullWidth
            >
              Add Location Mapping
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLocationDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SynthflowSubaccounts;
