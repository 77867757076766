import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Alert,
  MenuItem,
  Tooltip,
  FormControl,
  Snackbar,
  Box,
} from "@mui/material";
import { CAP_LANGUAGE } from "../../../../general/constants/constants";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function EditOrder({ order, productCatalog }) {
  const [status, setStatus] = useState(order.order_status);
  const [dailyCap, setDailyCap] = useState(order.daily_cap);
  const [show, setShow] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const currentUser = decode();
  
  // Find the corresponding product from catalog
  const product = productCatalog?.find(p => p.id === order.product_id);

  const handleClose = () => setShow(false);

  const handleShow = async () => {
    setShow(true);

    // Fetch locations
    try {
      const response = await customFetch(
        `/v1/users/${currentUser.tenant_user_id}/profile`
      );
      const data = await response.json();
      if (data.locations && data.locations.length > 0) {
        setLocations(data.locations);
        setSelectedLocation(data.primary_location_id);
      }
    } catch (error) {
      console.error("Failed to fetch locations:", error);
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);
  const handleStatusChange = (event) => setStatus(event.target.value);
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const updateOrder = async (e) => {
    e.preventDefault();
    const form = document.getElementById("edit-order");

    if (form.checkValidity() === false) {
      return;
    }

    // Validate daily cap against product minimum
    if (product && dailyCap < product.daily_cap) {
      setSnackbarMessage(`Daily cap cannot be less than ${product.daily_cap}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      const body = {
        order_status: status,
        daily_cap: dailyCap,
        location_id: selectedLocation !== "" ? selectedLocation : null,
      };

      const response = await customFetch(`/v1/orders/${order.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setSnackbarMessage("Order updated successfully!");
        setSnackbarSeverity("success");
      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || "Failed to update order.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      handleClose();
    }
  };

  const isRecentlyUpdated =
    order.last_updated &&
    Date.now() - new Date(order.last_updated).getTime() < 2 * 60 * 60 * 1000;

  return (
    <>
      <Tooltip
        title={
          order.order_status === "free"
            ? "Order is free and cannot be edited."
            : order.order_status === "fulfilled"
            ? "Order is either fulfilled or on hold and cannot be edited."
            : order.order_status === "capped" || order.order_status === "maxed"
            ? "Order has reached its daily limit and cannot be edited"
            : order.order_status === "adminhold"
            ? "Order is on administrative hold. Please contact support."
            : ""
        }
        arrow
      >
        <span>
          <Button
            size="small"
            variant={order.order_status === "free" ? "contained" : "contained"}
            color={order.order_status === "free" ? "primary" : "warning"}
            onClick={handleShow}
            disabled={
              order.order_status === "free" ||
              order.order_status === "fulfilled" ||
              order.order_status === "capped" ||
              order.order_status === "maxed" ||
              order.order_status === "adminhold" ||
              (order.order_status === "fulfilled" &&
                order.lead_count - order.replacement_count >= order.num_leads)
            }
          >
            {order.order_status === "free" ? "Free Order" : "Edit"}
          </Button>
        </span>
      </Tooltip>

      <Dialog 
        open={show} 
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Lead Order</DialogTitle>
        <DialogContent>
          {isRecentlyUpdated && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              This order was updated less than 2 hours ago.
            </Alert>
          )}
          <form id="edit-order">
            <TextField
              select
              label="Order Status"
              value={status}
              onChange={handleStatusChange}
              fullWidth
              sx={{ mb: 2, mt: 1 }}
              disabled={
                !(
                  order.order_status === "running" ||
                  order.order_status === "paused" ||
                  order.order_status === "hold"
                )
              }
            >
              <MenuItem value="running">Running</MenuItem>
              <MenuItem value="paused">Paused</MenuItem>
              <MenuItem value="hold">Hold</MenuItem>
              <MenuItem value="pending" disabled>
                Pending
              </MenuItem>
              <MenuItem value="capped" disabled>
                Capped
              </MenuItem>
              <MenuItem value="maxed" disabled>
                Daily Max
              </MenuItem>
              <MenuItem value="fulfilled" disabled>
                Fulfilled
              </MenuItem>
            </TextField>

            {locations.length > 0 && (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  select
                  label="GHL Delivery Account"
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  required
                  SelectProps={{
                    native: false,
                  }}
                  disabled={
                    !(
                      order.order_status === "running" ||
                      order.order_status === "paused" ||
                      order.order_status === "hold"
                    )
                  }
                  error={!selectedLocation}
                  helperText={
                    !selectedLocation ? "GHL Delivery Account is required" : ""
                  }
                >
                  {locations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}{" "}
                      {location.id === selectedLocation ? "(Primary)" : ""}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}

            <TextField
              required
              onChange={(e) => setDailyCap(e.target.value)}
              type="number"
              min={product?.daily_cap}
              value={dailyCap}
              label="Daily Lead Cap"
              fullWidth
              sx={{ mb: 2 }}
              inputProps={{
                min: product?.daily_cap || 0
              }}
              error={product && dailyCap < product.daily_cap}
              helperText={
                product ? `${product.name} minimum daily cap is ${product.daily_cap}`
                  : ""
              }
            />
            
            <Box sx={{ mb: 2 }}>{CAP_LANGUAGE}</Box>

            {(order.daily_cap !== dailyCap || order.order_status !== status) && (
              <Alert severity="error" sx={{ mt: 2 }}>
                Are you sure? <br />
                You will not be able to update this order again for 2 hours.
                {status === "paused" && (
                  <>
                    <br />
                    Some leads may still be delivered while paused.
                  </>
                )}
              </Alert>
            )}
          </form>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={updateOrder}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
