import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import {
  PhoneCall,
  PhoneOff,
  Clock,
  AlertTriangle,
  CheckCircle,
  XCircle,
} from "lucide-react";
import decode from "../../../../general/util/jwtDecode";
import customFetch from "../../../../general/auth/customFetch";
import { LicenseInfo } from "@mui/x-license";
import VoiceAIBreadcrumbs from "../components/VoiceAIBreadcrumbs";
import { formatDistanceToNow, format } from "date-fns";
import debounce from "lodash.debounce";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const CallHistoryDashboard = () => {
  const { integrationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = decode();
  const tenantId = currentUser.tenant_id;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [callHistory, setCallHistory] = useState([]);
  const [stats, setStats] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [timeInterval, setTimeInterval] = useState("hour");
  const [timeRange, setTimeRange] = useState({
    start_date: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
    end_date: new Date().toISOString(),
  });
  const [overallStats, setOverallStats] = useState({
    total_calls: 0,
    unique_contacts: 0,
    avg_call_duration: 0,
    max_call_duration: 0,
    success_rate: 0,
  });

  // Memoize URL construction for API calls
  const callHistoryUrl = useMemo(() => {
    let url = `/v1/tenantadmin/${tenantId}/voice-ai/call-history?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`;
    if (integrationId) {
      url += `&integration_id=${integrationId}`;
    }
    return url;
  }, [tenantId, integrationId, paginationModel.page, paginationModel.pageSize]);

  const timeSeriesUrl = useMemo(() => {
    return `/v1/tenantadmin/${tenantId}/voice-ai/call-history/stats?integration_id=${integrationId}&start_date=${timeRange.start_date}&end_date=${timeRange.end_date}&interval=${timeInterval}`;
  }, [tenantId, integrationId, timeRange.start_date, timeRange.end_date, timeInterval]);

  // Parse URL parameters on mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    
    // Parse pagination
    const page = parseInt(searchParams.get("page"), 10) || 0;
    const pageSize = parseInt(searchParams.get("pageSize"), 10) || 15;
    setPaginationModel({ page, pageSize });
  }, [location.search]);

  // Update URL with current state
  useEffect(() => {
    const params = new URLSearchParams();

    // Add pagination params
    params.set("page", paginationModel.page.toString());
    params.set("pageSize", paginationModel.pageSize.toString());

    navigate(`?${params.toString()}`, { replace: true });
  }, [paginationModel, navigate]);

  // Memoize handlers
  const handleIntervalChange = useCallback((event) => {
    const newInterval = event.target.value;
    setTimeInterval(newInterval);

    // Update time range based on interval
    const now = new Date();
    let startDate;

    switch (newInterval) {
      case "hour":
        startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "day":
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "week":
        startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      case "month":
        startDate = new Date(now.getTime() - 180 * 24 * 60 * 60 * 1000);
        break;
      default:
        startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    }

    setTimeRange({
      start_date: startDate.toISOString(),
      end_date: now.toISOString(),
    });
  }, []);

  const handlePaginationModelChange = useCallback((newModel) => {
    setPaginationModel(newModel);
  }, []);

  // Debounced fetch call history with memoized URL
  const debouncedFetchCallHistory = useCallback(
    debounce(async () => {
      try {
        setLoading(true);

        const response = await customFetch(callHistoryUrl);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || "Failed to fetch call history");
        }

        setCallHistory(data.calls);
        setStats(data.stats);
        setRowCount(data.pagination.total);
        setError(null);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching call history:", err);
      } finally {
        setLoading(false);
      }
    }, 250),
    [callHistoryUrl]
  );

  // Debounced load time series with memoized URL
  const debouncedLoadTimeSeriesData = useCallback(
    debounce(async () => {
      try {
        const response = await customFetch(timeSeriesUrl);
        if (!response.ok) throw new Error("Failed to load time series data");
        const data = await response.json();

        // Convert string values to numbers
        const processedData = data.timeseries.map((item) => ({
          ...item,
          total_calls: parseInt(item.total_calls) || 0,
          completed_calls: parseInt(item.completed_calls) || 0,
          failed_calls: parseInt(item.failed_calls) || 0,
          booked_outcomes: parseInt(item.booked_outcomes) || 0,
          not_interested_outcomes: parseInt(item.not_interested_outcomes) || 0,
          voicemail_count: parseInt(item.voicemail_count) || 0,
          followup_count: parseInt(item.followup_count) || 0,
          undefined_count: parseInt(item.undefined_count) || 0,
        }));

        setTimeSeriesData(processedData);
        setOverallStats(data.overall);
      } catch (err) {
        setError("Failed to load time series data");
      }
    }, 250),
    [timeSeriesUrl]
  );

  useEffect(() => {
    debouncedFetchCallHistory();
    // Cleanup
    return () => debouncedFetchCallHistory.cancel();
  }, [debouncedFetchCallHistory]);

  useEffect(() => {
    debouncedLoadTimeSeriesData();
    // Cleanup
    return () => debouncedLoadTimeSeriesData.cancel();
  }, [debouncedLoadTimeSeriesData]);

  const columns = [
    {
      field: "ghl_location_name",
      headerName: "Location",
      minWidth: 180,
      valueGetter: (params, row) => row.ghl_location_name || "N/A",
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      minWidth: 120,
      valueGetter: (params, row) => row.contact_name || "N/A",
    },
    {
      field: "contact_phone",
      headerName: "Phone",
      minWidth: 150,
      valueGetter: (params, row) => row.contact_phone || "N/A",
    },
    {
      field: "call_status",
      headerName: "Status",
      minWidth: 130,
      renderCell: (params) => {
        const status = params.value;
        let color = "default";
        let icon = null;
        let text = "";
        let tooltipText = "";

        switch (status) {
          case "completed":
            color = "success";
            text = "Completed";
            icon = <CheckCircle size={16} />;
            tooltipText = "Call completed successfully";
            break;
          case "failed":
            color = "error";
            text = "Failed";
            icon = <XCircle size={16} />;
            tooltipText = params.row.error_message || "Call failed";
            break;
          case "initiated":
            color = "info";
            text = "Initiated Call";
            icon = <PhoneCall size={16} />;
            tooltipText = "Call has been initiated";
            break;
          case "hangup_on_voicemail":
            color = "warning";
            text = "Voicemail";
            icon = <PhoneOff size={16} />;
            tooltipText = "Call ended in voicemail";
            break;
          case "queue":
            color = "warning";
            text = "In Queue";
            icon = <Clock size={16} />;
            tooltipText = "Call is queued";
            break;
          default:
            color = "default";
            icon = <Clock size={16} />;
            text = "Pending";
            tooltipText = "Call is pending";
        }

        return (
          <Tooltip title={tooltipText} arrow>
            <Chip
              icon={icon}
              label={text}
              size="small"
              color={color}
              variant="outlined"
            />
          </Tooltip>
        );
      },
    },
    {
      field: "call_outcome_type",
      headerName: "Outcome",
      minWidth: 130,
      renderCell: (params) => {
        // If the call status is failed, return empty content
        if (params.row.call_status === "failed") {
          return null;
        }
        
        const value = params.value;
        let color = "default";
        let text = "";
        
        switch (value) {
          case "booked":
            color = "info";
            text = "Booked"
            break;
          case "not_interested":
            color = "error";
            text = "Not Interested"
            break;
          case "followup":
            color = "#9c27b0";
            text = "Follow Up"
            break;
          case "voicemail":
            color = "warning";
            text = "Voicemail"
            break;
          case "no_answer":
            color = "warning";
            text = "No Answer"
            break;
          case "busy":
            color = "warning";
            text = "Busy"
            break;
          case "disconnected":
          case "bad_phone":
            color = "error";
            text = "Bad Phone"
            break;
          default:
            color = "default";
            text = "Pending";
        }
        
        return (
          <Chip
            label={text ? text.replace("_", " ") : "N/A"}
            size="small"
            color={["success", "error", "warning", "info"].includes(color) ? color : undefined}
            sx={{ 
              ...(color.startsWith("#") && {
                borderColor: color,
                color: color,
              })
            }}
            variant="outlined"
          />
        );
      },
    },
    {
      field: "call_duration",
      headerName: "Duration",
      minWidth: 100,
      valueGetter: (params, row) =>
        row.call_duration ? `${Math.round(row.call_duration / 60)}m ${row.call_duration % 60}s` : "N/A",
    },
    {
      field: "created_time",
      headerName: "Created",
      minWidth: 170,
      valueGetter: (params, row) =>
        formatDistanceToNow(new Date(row.created_time), { addSuffix: true }),
    },
    {
      field: "error_type",
      headerName: "Error",
      minWidth: 150,
      renderCell: (params) => {
        if (!params.value) return null;
        
        const errorType = params.value;
        const errorMessage = params.row.error_message;
        
        return (
          <Tooltip title={errorMessage || errorType} arrow>
            <Chip
              icon={<AlertTriangle size={16} />}
              label={errorType.replace(/_/g, ' ')}
              size="small"
              color="error"
              variant="outlined"
            />
          </Tooltip>
        );
      },
    },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <VoiceAIBreadcrumbs currentPage="Call History" />
      <Typography
        variant="h4"
        gutterBottom
        sx={{ mb: 4, color: "primary.main" }}
      >
        Call History Dashboard
      </Typography>
      
      {/* Time Interval Selector */}
      <Box display="flex" justifyContent="flex-end" mb={3}>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Time Interval</InputLabel>
          <Select
            value={timeInterval}
            label="Time Interval"
            onChange={handleIntervalChange}
          >
            <MenuItem value="hour">Hourly</MenuItem>
            <MenuItem value="day">Daily</MenuItem>
            <MenuItem value="week">Weekly</MenuItem>
            <MenuItem value="month">Monthly</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Stats Cards and Graphs */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" gutterBottom>
                Overall Statistics
              </Typography>
              <Grid container spacing={2} sx={{ flexGrow: 1, alignContent: 'space-around' }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Total Calls
                  </Typography>
                  <Typography variant="h4">{overallStats.total_calls}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Booked Rate
                  </Typography>
                  <Typography variant="h4">
                    {Math.round(overallStats.success_rate)}%
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Unique Contacts
                  </Typography>
                  <Typography variant="h4">{overallStats.unique_contacts}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Avg Duration
                  </Typography>
                  <Typography variant="h4">
                    {Math.round(overallStats.avg_call_duration / 60)}m
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Call Status
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart 
                  data={timeSeriesData} 
                  key={`status-${timeInterval}-${timeSeriesData.length}`}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="time_bucket"
                    tickFormatter={(time) => {
                      const date = new Date(time);
                      return timeInterval === 'hour' 
                        ? format(date, 'HH:mm')
                        : timeInterval === 'day'
                        ? format(date, 'MMM d')
                        : format(date, 'MMM d');
                    }}
                    interval="preserveStartEnd"
                  />
                  <YAxis 
                    domain={[0, 'auto']}
                    allowDataOverflow={false}
                  />
                  <RechartsTooltip
                    labelFormatter={(label) => {
                      const date = new Date(label);
                      return timeInterval === 'hour'
                        ? format(date, 'MMM d, HH:mm')
                        : format(date, 'MMM d, yyyy');
                    }}
                  />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="completed_calls"
                    name="Completed"
                    stroke="#4caf50"
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="failed_calls"
                    name="Failed"
                    stroke="#f44336"
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="total_calls"
                    name="Total"
                    stroke="#2196f3"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Call Outcomes Chart */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Call Outcomes
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart 
                  data={timeSeriesData} 
                  key={`outcomes-${timeInterval}-${timeSeriesData.length}`}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="time_bucket"
                    tickFormatter={(time) => {
                      const date = new Date(time);
                      return timeInterval === 'hour' 
                        ? format(date, 'HH:mm')
                        : timeInterval === 'day'
                        ? format(date, 'MMM d')
                        : format(date, 'MMM d');
                    }}
                    interval="preserveStartEnd"
                  />
                  <YAxis 
                    domain={[0, 'auto']}
                    allowDataOverflow={false}
                  />
                  <RechartsTooltip
                    labelFormatter={(label) => {
                      const date = new Date(label);
                      return timeInterval === 'hour'
                        ? format(date, 'MMM d, HH:mm')
                        : format(date, 'MMM d, yyyy');
                    }}
                  />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="booked_outcomes"
                    name="Booked"
                    stroke="#2196f3" // info color
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="not_interested_outcomes"
                    name="Not Interested"
                    stroke="#f44336" // error color
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="voicemail_count"
                    name="Voicemail"
                    stroke="#ff9800" // warning color
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="followup_count"
                    name="Follow Up"
                    stroke="#9c27b0" // purple color
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Call History Table */}
      <Paper sx={{ width: "100%", mb: 2 }}>
          <DataGridPro
            rows={callHistory}
            columns={columns}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[15, 25, 50]}
            paginationMode="server"
            rowCount={rowCount}
            disableSelectionOnClick
            getRowId={(row) => row.id}
            pagination
            slots={{
              noRowsOverlay: () => (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  p={2}
                >
                  <Typography>No call history found</Typography>
                </Box>
              ),
            }}
          />
      </Paper>
    </Container>
  );
};

export default CallHistoryDashboard;
