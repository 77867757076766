import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Chip,
  Stack,
  IconButton,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Edit } from "lucide-react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Volume2, VolumeX } from "lucide-react";
import { useParams } from "react-router-dom";
import decode from "../../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../../general/auth/customFetch";
import VoiceAIBreadcrumbs from "../components/VoiceAIBreadcrumbs";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const SynthflowAssistants = () => {
  const [assistants, setAssistants] = useState([]);
  const [leadTypes, setLeadTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentUser = decode();
  const { integrationId } = useParams();
  const [editingAssistant, setEditingAssistant] = useState(null);

  useEffect(() => {
    Promise.all([loadAssistants(), loadLeadTypes()]);
  }, [integrationId, currentUser.tenant_id]);

  const loadAssistants = async () => {
    try {
      const queryParams = integrationId
        ? `?integration_id=${integrationId}`
        : "";
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/synthflow/assistants${queryParams}`
      );
      if (response.ok) {
        const data = await response.json();
        setAssistants(data.assistants);
      }
    } catch (error) {
      console.error("Failed to load assistants:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadLeadTypes = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/synthflow/lead-types`
      );
      if (response.ok) {
        const data = await response.json();
        setLeadTypes(data.lead_types);
      }
    } catch (error) {
      console.error("Failed to load lead types:", error);
    }
  };

  const handleLeadTypeChange = async (assistantId, leadTypeId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/synthflow/assistants/${assistantId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ lead_type_id: leadTypeId }),
        }
      );

      if (response.ok) {
        loadAssistants(); // Refresh the list
      }
    } catch (error) {
      console.error("Failed to update assistant:", error);
    }
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const renderConfiguration = (params) => {
    const config = params.row.configuration || {};
    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ height: "100%" }}
      >
        {config.is_recording && (
          <Chip
            icon={<Volume2 className="w-4 h-4" />}
            label="Recording"
            size="small"
            color="success"
          />
        )}
        {!config.is_recording && (
          <Chip
            icon={<VolumeX className="w-4 h-4" />}
            label="No Recording"
            size="small"
            color="default"
          />
        )}
        {config.phone_number && (
          <Chip label={config.phone_number} size="small" color="primary" />
        )}
      </Stack>
    );
  };

  const handleLeadTypeDialogClose = () => {
    setEditingAssistant(null);
  };

  const handleLeadTypeUpdate = async (leadTypeId) => {
    await handleLeadTypeChange(editingAssistant.assistant_id, leadTypeId);
    setEditingAssistant(null);
  };

  const renderLeadTypeCell = (params) => {
    const leadType = leadTypes.find(
      (type) => type.id === params.row.lead_type_id
    );
    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            textAlign: "left",
            flex: "1",
          }}
        >
          {leadType ? leadType.name : "Not Set"}
        </Typography>
        <IconButton
          size="small"
          onClick={() => setEditingAssistant(params.row)}
        >
          <Edit size={16} />
        </IconButton>
      </Stack>
    );
  };

  const columns = [
    { field: "name", headerName: "Name",  minWidth: 200 },
    { field: "sub_account_name", headerName: "Sub Account", minWidth: 150 },
    { field: "description", headerName: "Description",  minWidth: 300 },
    { field: "type", headerName: "Type" },
    {
      field: "model_id",
      minWidth: 300,
      headerName: "Model ID",
            valueGetter: (value, row) => row.model_id || "Not Set",
    },
    {
      field: "lead_type_id",
      minWidth: 200,
      headerName: "Lead Type",
            renderCell: renderLeadTypeCell,
    },
    {
      field: "configuration",
      headerName: "Settings",
      minWidth: 250,
      renderCell: renderConfiguration,
    },
  ];

  return (
    <div className="content">
      <Container maxWidth={false} disableGutters={true}>
        <VoiceAIBreadcrumbs
          currentPage="Assistants"
          integrationId={integrationId}
        />
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{ color: "primary.main", mb: 2 }}
        >
          Synthflow Assistants
        </Typography>


          <div style={{ display: "flex", flexDirection: "column" }}>
            <DataGridPro
              rows={assistants}
              columns={columns}
              loading={loading}
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 25, 50]}
              pagination
              headerFilters
              slots={{
                toolbar: CustomToolbar,
                headerFilterMenu: null,
              }}
            />
          </div>


        <Dialog open={!!editingAssistant} onClose={handleLeadTypeDialogClose}>
          <DialogTitle>Select Lead Type</DialogTitle>
          <DialogContent>
            <Select
              fullWidth
              value={editingAssistant?.lead_type_id || ""}
              onChange={(e) => handleLeadTypeUpdate(e.target.value)}
              sx={{ mt: 2 }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {leadTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLeadTypeDialogClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default SynthflowAssistants;
