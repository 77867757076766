import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Box,
  Button,
  Chip,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Link as LinkIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import SlackIcon from "@mui/icons-material/Telegram";
import SvgIcon from "@mui/material/SvgIcon";

// Custom Stripe icon using SVG path
const StripeIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M13.479 9.883c-1.626-.604-2.512-1.067-2.512-1.803 0-.622.511-1.024 1.402-1.024 1.604 0 3.237.767 4.378 1.164l.655-4.034c-.897-.384-2.74-1.024-5.224-1.024-1.803 0-3.307.473-4.403 1.338-1.13.897-1.724 2.175-1.724 3.746 0 2.827 1.724 4.034 4.544 5.058 1.803.622 2.416 1.067 2.416 1.761 0 .664-.589 1.109-1.637 1.109-1.313 0-3.485-.675-4.925-1.569l-.655 4.127c1.234.737 3.526 1.493 5.903 1.493 1.93 0 3.541-.462 4.67-1.338 1.192-.918 1.82-2.282 1.82-3.961-.011-2.891-1.766-4.137-4.708-5.224z" />
  </SvgIcon>
);

const SynthflowIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M6.86 2.05c-1.25.27-2.17.77-3.13 1.72-.76.75-1.22 1.49-1.57 2.46-.21.61-.23.75-.23 1.93 0 1.16.02 1.33.23 1.93.32.97.75 1.63 1.58 2.46.78.79 1.46 1.21 2.59 1.62.52.19.57.19 5.21.23l4.68.04.39.19c.52.26.88.78.94 1.36.07.73-.21 1.31-.86 1.71-.3.18-.37.19-2.81.23l-2.5.04-.42.22c-1.62.85-1.62 3.14 0 3.99l.42.22h2.75c3.06 0 3.24-.02 4.39-.57 1.64-.78 2.91-2.32 3.38-4.07.23-.84.21-2.49-.04-3.28-.63-2.06-2.04-3.5-4.15-4.25l-.66-.23-4.68-.04c-4.37-.04-4.7-.05-4.97-.2-1.26-.67-1.37-2.32-.2-3.04l.39-.25 5.88-.04 5.88-.04.44-.24c.76-.43 1.17-1.13 1.16-2.01-.01-.84-.42-1.54-1.15-1.94l-.44-.25-5.96-.02c-5.03 0-6.05.01-6.53.11z" />
    <path d="M4.72 18c-.93.23-1.6.99-1.73 1.96-.16 1.26.95 2.48 2.27 2.48 1.12 0 2.22-1.1 2.22-2.22 0-.6-.23-1.15-.68-1.59-.6-.59-1.32-.81-2.07-.63z" />
  </SvgIcon>
);

// Go High Level icon using SVG path
const GHLIcon = (props) => (
  <SvgIcon {...props} viewBox="-100 -100 770 760" style={{ transform: 'scale(1.1)' }}>
    <path
      d="M287.12 3.65c156.55 0 283.47 124.52 283.47 278.13 0 153.61-126.92 278.13-283.47 278.13-156.55 0-283.47-124.52-283.47-278.13 0-153.61 126.92-278.13 283.47-278.13z"
      fill="#08223d"
    />
    <path
      d="M256.49 259.9h56.95v202.29h-56.95zM151.49 157.27h50.42v304.32h-50.42zM368.02 154.9h49.83v306.69h-49.83z"
      fill="#ffffff"
    />
    <path
      d="M98.1 173.29l158.39 1.18-78.9-83.64zM314.03 173.88l160.17.6-81.27-84.24zM208.44 291.93h156.61l-77.71-83.64z"
      fill="#ffffff"
    />
    <path
      d="M255.31 292.53l58.13 47.46v-47.46zM152.08 174.47l49.83 42.71v-42.71zM368.61 173.88l49.24 43.31-.59-43.31z"
      fill="#bad7f6"
    />
  </SvgIcon>
);

const getIntegrationIcon = (integrationType) => {
  switch (integrationType.toLowerCase()) {
    case "facebook":
      return <FacebookIcon fontSize="large" />;
    case "slack":
      return <SlackIcon fontSize="large" />;
    case "stripe":
      return <StripeIcon fontSize="large" />;
    case "synthflow":
      return <SynthflowIcon fontSize="large" />;
    case "go high level":
      return <GHLIcon fontSize="large" />;
    default:
      return null;
  }
};

const IntegrationCard = ({ integration, onEdit, onDelete, onConnect }) => {
  const isConnected = integration.connected;

  const renderFacebookData = () => {
    if (integration.integration_type !== "Facebook" || !integration.user_data) {
      return null;
    }

    return (
      <>
        <Typography variant="h6" component="div">
          Connection Details:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Oauth User: {integration.user_data.name}
        </Typography>
        {integration.user_data.email && (
          <Typography variant="body2" color="text.secondary">
            Oauth Email: {integration.user_data.email}
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          Facebook ID: {integration.user_data.facebook_id}
        </Typography>
        <Divider sx={{ my: 1 }} />
      </>
    );
  };

  const renderGHLData = () => {
    if (integration.integration_type !== "Go High Level" || !integration.user_data) {
      return null;
    }

    return (
      <>
        <Typography variant="h6" component="div">
          Connection Details:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Location: {integration.user_data.location_name || "N/A"}
        </Typography>
        {integration.user_data.email && (
          <Typography variant="body2" color="text.secondary">
            Email: {integration.user_data.email}
          </Typography>
        )}
        {integration.user_data.company_id && (
          <Typography variant="body2" color="text.secondary">
            Company ID: {integration.user_data.company_id}
          </Typography>
        )}
        <Divider sx={{ my: 1 }} />
      </>
    );
  };

  return (
    <Card sx={{ width: 350, mt: 2, mx: "auto" }}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box display="flex" alignItems="center">
            {getIntegrationIcon(integration.integration_type)}
            <Typography variant="h6" component="div" ml={1}>
              {integration.integration_type}
            </Typography>
          </Box>
          <Chip
            label={isConnected ? "Connected" : "Disconnected"}
            color={isConnected ? "success" : "default"}
            size="small"
          />
        </Box>
        <Typography variant="h5" component="div" mb={2}>
          {integration.name}
        </Typography>
        {renderFacebookData()}
        {renderGHLData()}
        <Typography variant="h6" component="div" mb={1}>
          Created by:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {integration.owner_name}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        {!isConnected && (
          <Button
            startIcon={<LinkIcon />}
            onClick={() => onConnect(integration)}
            variant="contained"
            color="primary"
            sx={{ flexGrow: 1, mr: 1 }}
          >
            Connect
          </Button>
        )}
        <Box>
          {isConnected && (
            <>
              <Tooltip title="Reconnect">
                <IconButton
                  aria-label="reconnect"
                  onClick={() => onConnect(integration)}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton aria-label="edit" onClick={() => onEdit(integration)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={() => onDelete(integration)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
};

export default IntegrationCard;
