import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  Chip,
} from "@mui/material";

function OverrunOrderSummary({ orderDetails = {} }) {
  const {
    productName = 'Overrun Tokens',
    description = '',
    quantity = 0,
    price = 0,
    currency = 'USD',
    total = 0,
    productType = ''
  } = orderDetails;

  const formatPrice = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          Order Summary
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              {productName}
            </Typography>
            {description && (
              <Typography variant="body2" color="text.secondary" mb={1}>
                {description}
              </Typography>
            )}
            <Typography mb={1} variant="body2" color="text.secondary">
              Price per token: <Chip label={formatPrice(price)} size="small" />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Quantity: <Chip label={quantity} size="small" />
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="subtitle1">Subtotal</Typography>
          <Typography variant="subtitle1">
            {formatPrice(total)}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            {formatPrice(total)}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
}

export default OverrunOrderSummary;