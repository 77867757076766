import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import UpdateTenantBranding from "./components/UpdateTenantBranding";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import Tooltip from "@mui/material/Tooltip";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const TenantManagement = () => {
  const currentUser = decode();
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({ 
    name: "", 
    description: "", 
    custom_domain: "",
    billing_email: "",
    support_email: "",
    billing_phone: "",
    support_phone: "",
    powered_by: true
  });
  const [editingTenant, setEditingTenant] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState(null);
  const [brandingOpen, setBrandingOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);

  const handleBrandingOpen = (tenant) => {
    setSelectedTenant(tenant);
    setBrandingOpen(true);
  };

  const handleBrandingClose = () => {
    setBrandingOpen(false);
    setSelectedTenant(null);
  };

  const fetchTenants = async () => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenants`
      );
      const data = await response.json();
      setTenants(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching tenants:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  const handleOpen = (tenant) => {
    if (tenant) {
      setEditingTenant(tenant);
      setForm({ 
        name: tenant.name, 
        description: tenant.description,
        custom_domain: tenant.custom_domain || "",
        billing_email: tenant.billing_email || "",
        support_email: tenant.support_email || "",
        billing_phone: tenant.billing_phone || "",
        support_phone: tenant.support_phone || "",
        powered_by: tenant.powered_by !== false
      });
    } else {
      setEditingTenant(null);
      setForm({ 
        name: "", 
        description: "", 
        custom_domain: "",
        billing_email: "",
        support_email: "",
        billing_phone: "",
        support_phone: "",
        powered_by: true
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({ 
      name: "", 
      description: "", 
      custom_domain: "",
      billing_email: "",
      support_email: "",
      billing_phone: "",
      support_phone: "",
      powered_by: true
    });
    setEditingTenant(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = async () => {
    if (editingTenant) {
      await updateTenant(editingTenant.id, form);
    } else {
      await createTenant(form);
    }
    handleClose();
    fetchTenants();
  };

  const createTenant = async (data) => {
    try {
      await customFetch(`/v1/partners/${currentUser.partner_id}/tenants`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("Error creating tenant:", error);
    }
  };

  const updateTenant = async (id, data) => {
    try {
      await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenants/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    } catch (error) {
      console.error("Error updating tenant:", error);
    }
  };

  const confirmDeleteTenant = (id) => {
    setTenantToDelete(id);
    setConfirmOpen(true);
  };

  const handleDeleteClose = () => {
    setConfirmOpen(false);
    setTenantToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (tenantToDelete) {
      try {
        await customFetch(
          `/v1/partners/${currentUser.partner_id}/tenants/${tenantToDelete}`,
          {
            method: "DELETE",
          }
        );
        fetchTenants();
      } catch (error) {
        console.error("Error deleting tenant:", error);
      } finally {
        handleDeleteClose();
      }
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1.5 },
    { field: "signup_token", headerName: "Signup Token", flex: 1.5 },
    { field: "billing_email", headerName: "Billing Email", flex: 1 },
    { field: "support_email", headerName: "Support Email", flex: 1 },
    { field: "billing_phone", headerName: "Billing Phone", flex: 1 },
    { field: "support_phone", headerName: "Support Phone", flex: 1 },
    { field: "powered_by", headerName: "Powered By", type: "boolean", flex: 0.7 },
    { field: "is_light_mode_set", headerName: "Light Mode", type: "boolean", flex: 0.7 },
    { field: "is_dark_mode_set", headerName: "Dark Mode", type: "boolean", flex: 0.7 },
    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleOpen(params.row)} aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => confirmDeleteTenant(params.row.id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
          <Tooltip title="Update Tenant Branding">
            <IconButton
              onClick={() => handleBrandingOpen(params.row)}
              aria-label="branding"
            >
              <BrandingWatermarkIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="content">
      <div style={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main", // Use the primary color from the theme
            marginBottom: 2, // Theme spacing unit
            textAlign: "center", // Center align text
          }}
        >
          Tenant Management
        </Typography>
      </div>
      <Box sx={{ height: 600, width: "100%" }}>
        <Button variant="contained" onClick={() => handleOpen(null)}>
          Add Tenant
        </Button>
        <DataGridPro
          rows={tenants}
          columns={columns}
          pageSize={10}
          loading={loading}
          initialState={{
            columns: {
              columnVisibilityModel: {
                signup_token: false,
              },
            },
          }}
          disableSelectionOnClick
        />
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: { minHeight: '60vh' }
          }}
        >
          <DialogTitle>
            {editingTenant ? "Edit Tenant" : "Add Tenant"}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ 
              display: "flex", 
              flexDirection: "column", 
              gap: 2,
              mt: 2,
              '& .MuiTextField-root': {
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1
                }
              }
            }}>
              {/* Basic Information */}
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                <TextField
                  label="Name"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  label="Custom Domain"
                  name="custom_domain"
                  value={form.custom_domain}
                  onChange={handleChange}
                  fullWidth
                  helperText="e.g., app.yourdomain.com"
                />
              </Box>

              <TextField
                label="Description"
                name="description"
                value={form.description}
                onChange={handleChange}
                fullWidth
                required
                multiline
                rows={2}
              />

              {/* Contact Information */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 'medium' }}>
                Contact Information
              </Typography>
              
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                <TextField
                  label="Billing Email"
                  name="billing_email"
                  value={form.billing_email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                />
                <TextField
                  label="Billing Phone"
                  name="billing_phone"
                  value={form.billing_phone}
                  onChange={handleChange}
                  fullWidth
                />

              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              <TextField
                  label="Support Email"
                  name="support_email"
                  value={form.support_email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                />
                <TextField
                  label="Support Phone"
                  name="support_phone"
                  value={form.support_phone}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>

              {/* Settings */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 'medium' }}>
                Settings
              </Typography>
              
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.powered_by}
                    onChange={(e) => setForm(prev => ({ ...prev, powered_by: e.target.checked }))}
                    name="powered_by"
                  />
                }
                label="Show 'Powered by' Label"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>
              {editingTenant ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmOpen} onClose={handleDeleteClose}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this tenant?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Cancel</Button>
            <Button onClick={handleDeleteConfirm} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <UpdateTenantBranding
        open={brandingOpen}
        onClose={handleBrandingClose}
        tenant={selectedTenant}
        fetchTenants={fetchTenants}
      />
    </div>
  );
};

export default TenantManagement;
