import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  CircularProgress,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import customFetch from "../auth/customFetch";

const fetchLeadsData = async (productId) => {
  const query = productId ? `?product_id=${productId}` : "";
  const response = await customFetch(
    `/v1/dashboards/leads-by-state-and-age-range${query}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const processLeadsData = (data) => {
  const states = [];
  const ageRanges = [
    "0-2days",
    "2days-7days",
    "7days-30days",
    "30days-90days",
    "90days+",
  ];
  const seriesData = [];

  data.forEach((item) => {
    if (!states.includes(item.stack)) {
      states.push(item.stack);
    }

    ageRanges.forEach((range, index) => {
      let series = seriesData.find((s) => s.label === range);
      if (!series) {
        series = {
          label: range,
          data: Array(states.length).fill(0),
          stack: item.stack,
        };
        seriesData.push(series);
      }
      series.data[states.indexOf(item.stack)] = item[range];
    });
  });

  return {
    states,
    seriesData,
  };
};

const LeadsByStateAndAgeChart = ({ productId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      try {
        const result = await fetchLeadsData(productId);
        const processedData = processLeadsData(result);
        setData(processedData);
        setError(null);
      } catch (err) {
        console.error("Error fetching leads data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [productId]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography color="error">{error.message}</Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}
    >
      <Card sx={{ maxWidth: 1200, width: "100%" }}>
        <CardHeader
          titleTypographyProps={{
            align: "left",
            noWrap: true,
            style: { textOverflow: "ellipsis", overflow: "hidden" },
          }}
          title="Leads Overrun Availability"
        />
        <CardContent>
          <BarChart
            series={data.seriesData}
            height={400}
            xAxis={[{ data: data.states, scaleType: "band" }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default LeadsByStateAndAgeChart;
