import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Button,
  CircularProgress,
  Typography,
  Grid,
  Paper,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OverrunOrderSummary from "./OverrunOrderSummary";

export default function OverrunCheckoutForm({ orderDetails, orderId }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          setIsError(false);
          navigate(`/overrun-order-completion`);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          setIsError(false);
          break;
        case "requires_payment_method":
          setMessage("Please provide your payment details.");
          setIsError(false);
          break;
        default:
          setMessage("Something went wrong with your payment.");
          setIsError(true);
          break;
      }
    });
  }, [stripe, navigate, orderId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setMessage(null);
    setIsError(false);

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/overrun-order-completion`,
        },
      });

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred during payment processing.");
        }
        setIsError(true);
      }
    } catch (error) {
      console.error("Payment confirmation error:", error);
      setMessage("Failed to process payment. Please try again.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <OverrunOrderSummary orderDetails={orderDetails} />
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
          <Typography variant="h5" gutterBottom fontWeight="bold">
            Payment Details
          </Typography>
          <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement 
              id="payment-element" 
              options={{
                layout: "tabs",
                wallets: {
                  applePay: "auto",
                  googlePay: "auto"
                }
              }}
            />
            {message && (
              <Alert 
                severity={isError ? "error" : "info"} 
                sx={{ mt: 2 }}
              >
                {message}
              </Alert>
            )}
            <Button
              variant="contained"
              disabled={isLoading || !stripe || !elements}
              type="submit"
              fullWidth
              sx={{ mt: 3 }}
              size="large"
            >
              {isLoading ? <CircularProgress size={24} /> : "Pay now"}
            </Button>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}