import React from 'react';
import { SynthflowEditDialog } from './SynthflowEditDialog';
import { FacebookEditDialog } from './FacebookEditDialog';
import { StripeEditDialog } from './StripeEditDialog';

export function EditIntegration({ open, onClose, integration, onUpdateSuccess }) {
  if (!integration) return null;

  switch (integration.integration_type) {
    case 'Synthflow':
      return (
        <SynthflowEditDialog
          open={open}
          onClose={onClose}
          integration={integration}
          onUpdateSuccess={onUpdateSuccess}
        />
      );
    
    case 'Facebook':
      return (
        <FacebookEditDialog
          open={open}
          onClose={onClose}
          integration={integration}
          onUpdateSuccess={onUpdateSuccess}
        />
      );

    case 'Stripe':
      return (
        <StripeEditDialog
          open={open}
          onClose={onClose}
          integration={integration}
          onUpdateSuccess={onUpdateSuccess}
        />
      );

    default:
      return null;
  }
}