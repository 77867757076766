import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch.js";
import decode from "../../../../general/util/jwtDecode.js";

export const GHLEditDialog = ({ open, onClose, integration, onUpdateSuccess }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ghlApps, setGhlApps] = useState([]);
  const [selectedAppId, setSelectedAppId] = useState("");
  const currentUser = decode();

  useEffect(() => {
    if (open) {
      fetchGhlApps();
      // If there's already a selected app in user_data, preselect it
      if (integration?.user_data?.ghlApp?.id) {
        setSelectedAppId(integration.user_data.ghlApp.id);
      }
    }
  }, [open, integration]);

  const fetchGhlApps = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/integrations/ghl/apps`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch GHL apps");
      }
      const data = await response.json();
      setGhlApps(data.apps);
    } catch (err) {
      setError("Failed to load GHL apps. Please try again.");
      console.error("Error fetching GHL apps:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAppSelect = async () => {
    try {
      setError(null);
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/integrations/ghl/${integration.id}/select-app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ghlAppId: selectedAppId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save app selection");
      }

      const data = await response.json();
      if (onUpdateSuccess) {
        onUpdateSuccess({
          ...integration,
          user_data: { ghlApp: data.ghlApp },
        });
      }
      
      // Proceed with OAuth
      const selectedApp = ghlApps.find(app => app.id === selectedAppId);
      const options = {
        requestType: "code",
        redirectUri: `${window.location.origin}/auth/oauth/callback/hl`,
        clientId: selectedApp.client_id,
        scopes: [
          "companies.readonly",
          "businesses.readonly",
          "locations.readonly",
          "locations/customValues.readonly",
          "locations/customValues.write",
          "locations/customFields.readonly",
          "locations/customFields.write",
          "locations/tasks.readonly",
          "locations/tasks.write",
          "locations/tags.readonly",
          "locations/tags.write",
          "locations/templates.readonly",
          "contacts.readonly",
          "contacts.write",
          "users.readonly",
        ],
      };

      // Store the integration ID and tenant_id in sessionStorage before redirecting
      sessionStorage.setItem("ghl_integration_id", integration.id);
      sessionStorage.setItem("ghl_tenant_id", currentUser.tenant_id);

      // Build the OAuth URL
      const authUrl = new URL("https://marketplace.gohighlevel.com/oauth/chooselocation");
      authUrl.searchParams.append("response_type", options.requestType);
      authUrl.searchParams.append("redirect_uri", options.redirectUri);
      authUrl.searchParams.append("client_id", options.clientId);
      authUrl.searchParams.append("scope", options.scopes.join(" "));

      // Open the OAuth window
      const width = 600;
      const height = 700;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;
      
      window.open(
        authUrl.toString(),
        "ghlOAuth",
        `width=${width},height=${height},left=${left},top=${top}`
      );

      // Listen for the OAuth callback message
      const handleMessage = (event) => {
        if (event.origin !== window.location.origin) return;

        if (event.data.type === "GHL_OAUTH_SUCCESS") {
          // Handle successful OAuth
          if (onUpdateSuccess) {
            onUpdateSuccess({
              ...integration,
              ...event.data.data
            });
          }
          onClose();
        } else if (event.data.type === "GHL_OAUTH_CANCELLED") {
          // Handle OAuth cancellation
          setError("GHL integration was cancelled");
        }

        // Clean up the message listener
        window.removeEventListener("message", handleMessage);
      };

      window.addEventListener("message", handleMessage);
    } catch (err) {
      setError("Failed to save app selection. Please try again.");
      console.error("Error saving app selection:", err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Connect Go High Level</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box sx={{ my: 2 }}>
          <Typography variant="body1" gutterBottom>
            Select a GHL app to connect with:
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>GHL App</InputLabel>
            <Select
              value={selectedAppId}
              onChange={(e) => setSelectedAppId(e.target.value)}
              label="GHL App"
              disabled={isLoading}
            >
              {ghlApps.map((app) => (
                <MenuItem key={app.id} value={app.id}>
                  {app.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {isLoading && (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleAppSelect}
          variant="contained"
          color="primary"
          disabled={isLoading || !selectedAppId}
        >
          Connect
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GHLEditDialog;
