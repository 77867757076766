import React, { useState, useEffect } from "react";
import {
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Alert,
  Select as MuiSelect,
  InputLabel,
  FormControl,
  FormGroup,
  Box,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { statesList } from "../../../../general/util/statesList";
import { orderStatusList } from "../../../../general/util/orderStatusList";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function EditOrder({
  order,
  tenantUsersList,
  updateOrderInState,
}) {
  const [id, setId] = useState(order.id);
  const [productId, setProductId] = useState(order.product_id);
  const [productKey, setProductKey] = useState("");
  const [numLeads, setNumLeads] = useState(parseInt(order.num_leads, 10));
  const [dailyCap, setDailyCap] = useState(order.daily_cap);
  const [status, setStatus] = useState(order.order_status);
  const [startDate, setStartDate] = useState(
    (order.start_date && new Date(order.start_date)) || null
  );
  const [orderDate, setOrderDate] = useState(
    (order.order_date && new Date(order.order_date)) || new Date()
  );
  const [states, setStates] = useState(order.states);
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const [ghlLocationId, setGhlLocationId] = useState(
    order.ghl_location_id || null
  );
  const [ghlLocations, setGhlLocations] = useState([]);
  const [ghlUserId, setGhlUserId] = useState(order.ghl_user_id);
  const currentUser = decode();

  const handleClose = () => setShow(false);

  const handleShow = async () => {
    setShow(true);
    if (productId) {
      await fetchProducts();
    }
    if (ghlUserId) {
      await fetchGhlUserAndLocations(ghlUserId);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/product-catalog?tenant_id=${order.tenant_id}`
      );
      const data = await response.json();
      setProducts(data);

      const selectedProduct = data.find((prod) => prod.id === productId);
      if (selectedProduct) {
        setProductKey(selectedProduct.product_key);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };

  const fetchGhlUserAndLocations = async (ghlUserId) => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/ghl-users/${ghlUserId}`
      );
      const ghlUser = await response.json();

      const locationsPromises = ghlUser.roles.locationIds.map((locationId) =>
        customFetch(
          `/v1/partners/${currentUser.partner_id}/ghl-locations/${locationId}`
        )
      );

      const locationsResponses = await Promise.all(locationsPromises);
      const locationsData = await Promise.all(
        locationsResponses.map((res) => res.json())
      );

      const formattedLocations = locationsData.map((location) => ({
        ...location,
        label:
          location.name +
          (location.ghl_location_id === ghlUser.primary_location_id
            ? " (Primary)"
            : ""),
      }));

      setGhlLocations(formattedLocations);
    } catch (error) {
      console.error("Failed to fetch GHL user and locations:", error);
    }
  };

  const editableStatuses = [
    "pending",
    "running",
    "capped",
    "maxed",
    "paused",
    "hold",
    "adminhold",
  ];
  const nonEditableStatuses = ["fulfilled", "checkout", "created"];
  const isStatusEditable = editableStatuses.includes(status);
  const isOrderEditable = !nonEditableStatuses.includes(status);

  const handleStatusChange = (e) => setStatus(e.target.value);

  const handleProductChange = (e) => {
    const selectedProductId = e.target.value;
    setProductId(selectedProductId);

    const selectedProduct = products.find(
      (prod) => prod.id === selectedProductId
    );
    setProductKey(selectedProduct?.product_key || "");

    if (selectedProduct?.product_key === "agent-adset") {
      setStates([]);
    }
  };

  const updateOrder = async (e) => {
    e.preventDefault();
    try {
      const body = {
        id: id,
        product_id: productId || null,
        num_leads: numLeads,
        order_status: status,
        start_date: startDate,
        order_date: orderDate,
        states: productKey === "agent-adset" ? null : states,
        daily_cap: dailyCap,
        ghl_location_id: ghlLocationId,
      };
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/orders/${order.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      if (response.ok) {
        const updatedOrder = await response.json();
        updateOrderInState(updatedOrder);
        handleClose();
      } else {
        console.error("Failed to update order");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton onClick={handleShow}>
        <EditIcon />
      </IconButton>

      <Dialog open={show} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Lead Order</DialogTitle>
        <DialogContent>
          <form id="edit-order">
            <FormGroup>
              <TextField
                label="Order ID"
                onChange={(e) => setId(e.target.value)}
                type="text"
                defaultValue={id}
                fullWidth
                margin="normal"
                disabled
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Product</InputLabel>
                <MuiSelect
                  label="Product"
                  value={productId}
                  onChange={handleProductChange}
                >
                  {products.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
              <TextField
                label="Number of Leads"
                onChange={(e) => setNumLeads(parseInt(e.target.value, 10) || 0)}
                type="number"
                min={0}
                value={numLeads}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Daily Cap"
                onChange={(e) => setDailyCap(e.target.value)}
                type="number"
                min={0}
                defaultValue={dailyCap}
                fullWidth
                margin="normal"
              />
              {dailyCap < 10 && (
                <Alert severity="warning" className="mt-1 py-1">
                  Minimum of 10 leads per day... Are you sure you want to
                  continue with {dailyCap} leads per day?
                </Alert>
              )}
              <FormControl fullWidth margin="normal">
                <InputLabel>Order Status</InputLabel>
                <MuiSelect
                  label="Order Status"
                  value={status}
                  onChange={handleStatusChange}
                >
                  {orderStatusList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  margin: "16px 0px 8px 0px",
                }}
              >
                <DesktopDatePicker
                  label="Order Date"
                  inputFormat="MM/dd/yyyy"
                  value={orderDate}
                  onChange={(date) => setOrderDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="normal" />
                  )}
                />
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="normal" />
                  )}
                />
              </Box>

              {productKey !== "agent-adset" && (
                <Autocomplete
                  multiple
                  options={statesList}
                  getOptionLabel={(option) => option.label}
                  value={statesList.filter((state) =>
                    states.includes(state.value)
                  )}
                  onChange={(event, newValue) => {
                    setStates(newValue.map((option) => option.value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="States"
                      placeholder="States..."
                      fullWidth
                      margin="normal"
                    />
                  )}
                  disabled={productKey === "agent-adset"} // Disable states list for "agent-adset"
                />
              )}

              {ghlUserId && (
                <Autocomplete
                  options={ghlLocations}
                  getOptionLabel={(option) => option.label}
                  value={
                    ghlLocations.find(
                      (location) => location.id === ghlLocationId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setGhlLocationId(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="GHL Sub Accounts"
                      placeholder="Select an account..."
                      fullWidth
                      margin="normal"
                    />
                  )}
                />
              )}
            </FormGroup>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button
            onClick={updateOrder}
            color="primary"
            form="edit-order"
            type="submit"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
