import React, { useEffect, useState, useCallback } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  Stack,
  Link,
  Chip,
  LinearProgress,
  Divider,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import customFetch from "../../../../general/auth/customFetch";
import StripeProvider from "../../../../StripeProvider";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ListAltIcon from '@mui/icons-material/ListAlt';

const POLLING_INTERVAL = 3000; // 3 seconds
const MAX_POLLING_ATTEMPTS = 20; // Maximum 1 minute of polling (20 * 3 seconds)

function OrderCompletionContent() {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectStatus, setRedirectStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [pollingCount, setPollingCount] = useState(0);
  const [isPolling, setIsPolling] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const fetchOrderDetails = useCallback(
    async (paymentIntentId) => {
      try {
        const response = await customFetch(
          `/v1/orders/payment-intent/${paymentIntentId}`
        );
        const data = await response.json();
        setOrderDetails(data);

        // If the order status is still pending and we haven't reached max attempts,
        // continue polling
        if (
          (!data.stripe_charge_id) &&
          pollingCount < MAX_POLLING_ATTEMPTS
        ) {
          return false; // Continue polling
        }

        return true; // Stop polling
      } catch (error) {
        console.error("Error fetching order details:", error);
        return true; // Stop polling on error
      }
    },
    [pollingCount]
  );

  useEffect(() => {
    let pollingTimeout;

    const startPolling = async (paymentIntentId) => {
      setIsPolling(true);
      const shouldStopPolling = await fetchOrderDetails(paymentIntentId);

      if (!shouldStopPolling && pollingCount < MAX_POLLING_ATTEMPTS) {
        setPollingCount((prev) => prev + 1);
        pollingTimeout = setTimeout(
          () => startPolling(paymentIntentId),
          POLLING_INTERVAL
        );
      } else {
        setIsPolling(false);
        setLoading(false);
      }
    };

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const paymentIntentId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    const redirectStatus = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );

    setRedirectStatus(redirectStatus);

    if (!clientSecret || !paymentIntentId) {
      setMessage("Payment details are missing. Please contact support.");
      setLoading(false);
      return;
    }

    const initializePayment = async () => {
      try {
        const { paymentIntent } = await stripe.retrievePaymentIntent(
          clientSecret
        );

        if (!paymentIntent) {
          setMessage("No payment intent found.");
          setLoading(false);
          return;
        }

        setPaymentStatus(paymentIntent.status);

        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            await startPolling(paymentIntentId);
            break;
          case "processing":
            setMessage("Your payment is processing.");
            await startPolling(paymentIntentId);
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            await fetchOrderDetails(paymentIntentId);
            setLoading(false);
            break;
          case "requires_action":
            setMessage("Payment requires additional authentication.");
            await startPolling(paymentIntentId);
            break;
          default:
            setMessage("Something went wrong.");
            setLoading(false);
            break;
        }
      } catch (error) {
        console.error("PaymentIntent retrieval error:", error);
        setMessage("Failed to retrieve payment details. Please try again.");
        setLoading(false);
      }
    };

    initializePayment();

    return () => {
      clearTimeout(pollingTimeout);
    };
  }, [stripe, pollingCount, fetchOrderDetails]);

  const handleGoToOrders = () => {
    navigate("/orders");
  };

  const handleStartOrder = async (orderId) => {
    try {
      const body = {
        order_status: "running",
        daily_cap: orderDetails.daily_cap,
        start_date: new Date(),
        states: orderDetails.states,
      };

      const response = await customFetch(`/v1/orders/${orderId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        // Refetch order details to update the UI
        const updatedResponse = await customFetch(
          `/v1/orders/payment-intent/${searchParams.get("payment_intent")}`
        );
        const updatedData = await updatedResponse.json();
        setOrderDetails(updatedData);
      } else {
        console.error("Failed to start order");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderOrderStatusChip = (status) => {
    let color = "default";
    let statusText = status;
    let icon = null;

    switch (status) {
      case "paid":
        color = "success";
        icon = <CheckCircleIcon fontSize="small" />;
        break;
      case "failed":
      case "canceled":
        color = "error";
        icon = <ErrorIcon fontSize="small" />;
        break;
      case "pending":
        color = "warning";
        statusText = "Pending order start";
        icon = <AccessTimeIcon fontSize="small" />;
        break;
      default:
        color = "default";
    }

    return (
      <Chip 
        icon={icon}
        label={statusText.toUpperCase()} 
        color={color}
        sx={{ fontWeight: 'medium' }}
      />
    );
  };

  const OrderSummary = ({ orderDetails }) => (
    <Box sx={{ mt: 4 }}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 3 }}>
        <ReceiptLongIcon color="primary" />
        <Typography variant="h5" component="h2">
          Order Summary
        </Typography>
      </Stack>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Order Information
              </Typography>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Order ID
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    <Chip
                      label={orderDetails.id}
                      size="small"
                    />
                  </Stack>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Order Date
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {new Date(orderDetails.order_date).toLocaleDateString()}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Status
                  </Typography>
                  {renderOrderStatusChip(orderDetails.order_status)}
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Order Details
              </Typography>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Leads Ordered
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    <Chip
                      label={orderDetails.num_leads}
                      size="small"
                    />
                  </Stack>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    States
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
                    {orderDetails.states ? orderDetails.states.map((state, index) => (
                      <Chip
                        key={index}
                        label={state}
                        size="small"
                      />
                    )) : (
                      <Typography variant="body1" fontWeight="medium">N/A</Typography>
                    )}
                  </Stack>
                </Box>
                {orderDetails.stripe_charge_id && (
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Payment Reference
                    </Typography>
                    <Typography variant="body1" fontWeight="medium" sx={{ wordBreak: "break-all" }}>
                      {orderDetails.stripe_charge_id}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  const OrderActions = () => {
    const showStartNow = orderDetails?.order_status === "pending";
    
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
        {showStartNow && (
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={() => handleStartOrder(orderDetails.id)}
            startIcon={<PlayArrowIcon />}
            sx={{ minWidth: 200 }}
          >
            Start Now
          </Button>
        )}
        <Button
          variant="contained"
          size="large"
          onClick={() => navigate("/orders")}
          startIcon={<ListAltIcon />}
          sx={{ minWidth: 200 }}
        >
          View All Orders
        </Button>
      </Box>
    );
  };

  const LoadingState = () => (
    <Box sx={{ textAlign: "center", py: 4 }}>
      <CircularProgress size={48} sx={{ mb: 2 }} />
      <Typography variant="h6" gutterBottom>
        Processing your payment...
      </Typography>
      {isPolling && (
        <Box sx={{ width: "100%", maxWidth: 400, mx: "auto", mt: 3 }}>
          <LinearProgress
            variant="determinate"
            value={(pollingCount / MAX_POLLING_ATTEMPTS) * 100}
            sx={{ height: 8, borderRadius: 4 }}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Confirming payment details...
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", p: { xs: 2, sm: 3, md: 4 } }}>
      {loading ? (
        <LoadingState />
      ) : (
        <Stack spacing={4}>
          {message && (
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                justifyContent: 'center'
              }}
            >
              {paymentStatus === "succeeded" ? (
                <CheckCircleIcon color="success" fontSize="large" />
              ) : (
                <ErrorIcon color="error" fontSize="large" />
              )}
              <Typography
                variant="h5"
                color={paymentStatus === "succeeded" ? "success.main" : "error.main"}
              >
                {message}
              </Typography>
            </Box>
          )}
          
          {orderDetails && <OrderSummary orderDetails={orderDetails} />}
          
          {orderDetails && <OrderActions />}
        </Stack>
      )}
    </Box>
  );
}

// Wrapper component that handles loading the order details and providing the product_id
function OrderCompletion() {
  const [searchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const paymentIntentId = searchParams.get("payment_intent");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!paymentIntentId) {
        setLoading(false);
        return;
      }

      try {
        const response = await customFetch(`/v1/orders/payment-intent/${paymentIntentId}`);
        const data = await response.json();
        setOrderDetails(data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [paymentIntentId]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!orderDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography color="error">
          Unable to load order details. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <StripeProvider productId={orderDetails.product_id}>
      <OrderCompletionContent />
    </StripeProvider>
  );
}

export default OrderCompletion;
