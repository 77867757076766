import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import decode from "../../../general/util/jwtDecode.js";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch.js";
import CreateIntegration from "./components/CreateIntegration.js";
import IntegrationCard from "./shared/IntegrationCard.js";
import {
  GROUP_ADMIN_ROLE_NAME,
  TENANT_ADMIN_ROLE_NAME,
} from "../../../general/constants/constants.js";
import { EditIntegration } from "./components/EditIntegration.js";
import { GHLEditDialog } from "./components/GHLEditDialog.js";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function Integrations() {
  const [integrations, setIntegrations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [integrationToDelete, setIntegrationToDelete] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const currentUser = decode();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Add these new state variables
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isGHLDialogOpen, setIsGHLDialogOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  // Update the handleEdit function to handle different integration types
  const handleEdit = (integration) => {
    setSelectedIntegration(integration);
    if (integration.integration_type === "Go High Level") {
      setIsGHLDialogOpen(true);
    } else {
      setIsEditDialogOpen(true);
    }
  };

  // Add this function to close the edit dialog
  const handleEditClose = () => {
    setIsEditDialogOpen(false);
    setIsGHLDialogOpen(false);
    setSelectedIntegration(null);
  };

  const handleUpdateSuccess = (updatedIntegration) => {
    if (!updatedIntegration || !updatedIntegration.id) {
      console.error('Invalid updated integration data:', updatedIntegration);
      return;
    }

    setIntegrations((prevIntegrations) =>
      prevIntegrations.map((integration) =>
        integration.id === updatedIntegration.id
          ? { ...integration, data: updatedIntegration.data }
          : integration
      )
    );
    setSnackbar({
      open: true,
      message: "Integration updated successfully",
      severity: "success",
    });
  };

  useEffect(() => {
    if (
      currentUser &&
      currentUser.role !== TENANT_ADMIN_ROLE_NAME &&
      currentUser.role !== GROUP_ADMIN_ROLE_NAME
    ) {
      window.location = "/login";
      return;
    }
    fetchIntegrations();
  }, []);

  const fetchIntegrations = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/integrations`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch integrations");
      }
      const data = await response.json();
      setIntegrations(data.integrations);
    } catch (error) {
      console.error("Error fetching integrations:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (integration) => {
    setIntegrationToDelete(integration);
    setDeleteDialogOpen(true);
    setDeleteError(null); // Clear any previous errors
  };

  const handleConfirmDelete = async () => {
    if (integrationToDelete) {
      try {
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/integrations/${integrationToDelete.id}`,
          {
            method: "DELETE",
          }
        );
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to delete integration");
        }
        fetchIntegrations(); // Refresh the list after deletion
        setDeleteDialogOpen(false);
        setIntegrationToDelete(null);
      } catch (error) {
        console.error("Error deleting integration:", error);
        setDeleteError(error.message);
      }
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIntegrationToDelete(null);
    setDeleteError(null);
  };

  const handleConnect = async (integration) => {
    switch (integration.integration_type) {
      case "Synthflow":
        setSelectedIntegration(integration);
        setIsEditDialogOpen(true);
        break;

      case "Stripe":
        setSelectedIntegration(integration);
        setIsEditDialogOpen(true);
        break;

      case "Facebook":
        initiateFacebookAuth(integration.id);
        break;

      case "Go High Level":
        setSelectedIntegration(integration);
        setIsGHLDialogOpen(true);
        break;

      default:
        console.warn(
          `Unknown integration type: ${integration.integration_type}`
        );
        setSnackbar({
          open: true,
          message: "Unsupported integration type",
          severity: "warning",
        });
    }
  };

  const initiateFacebookAuth = async (integrationId) => {
    try {
      localStorage.setItem('facebookAuthOpenerOrigin', window.location.origin);

      const response = await customFetch(
        `/v1/auth/facebook/oauth?integration_id=${integrationId}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to initiate Facebook authentication");
      }

      const data = await response.json();

      window.addEventListener("message", handleFacebookCallback);
      
      const popup = window.open(
        data.url,
        "FacebookAuth",
        "width=800,height=1000"
      );

      const pollTimer = setInterval(() => {
        if (popup.closed) {
          clearInterval(pollTimer);
          setTimeout(() => {
            window.removeEventListener("message", handleFacebookCallback);
            localStorage.removeItem('facebookAuthOpenerOrigin');
            fetchIntegrations();
          }, 1000); 
        }
      }, 500);
    } catch (error) {
      console.error("Error initiating Facebook auth:", error);
      window.removeEventListener("message", handleFacebookCallback);
      localStorage.removeItem('facebookAuthOpenerOrigin');
      setSnackbar({
        open: true,
        message: "Failed to initiate Facebook authentication",
        severity: "error",
      });
    }
  };

  const handleFacebookCallback = async (event) => {
    if (event.data.type !== "FACEBOOK_OAUTH_CALLBACK") {
      return; 
    }

    if (event.origin !== window.location.origin) {
      return;
    }

    const { code, state } = event.data;
    
    try {
      const response = await customFetch("/v1/auth/facebook/callback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, state }),
      });

      if (!response.ok) {
        throw new Error("Failed to complete Facebook authentication");
      }

      setSnackbar({
        open: true,
        message: "Facebook integration connected successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error in Facebook callback:", error);
      setSnackbar({
        open: true,
        message: "Failed to complete Facebook authentication",
        severity: "error",
      });
    }
  };

  const handleCreateSuccess = (newIntegration) => {
    setIntegrations([...integrations, newIntegration]);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Typography variant="h6">Loading integrations...</Typography>;
    }

    if (integrations.length === 0) {
      return (
        <Typography variant="h6" color="text.secondary">
          No integrations created yet. Click "Add Integration" to get started.
        </Typography>
      );
    }

    return (
      <Grid container spacing={2} justifyContent="left">
        {integrations.map((integration) => (
          <Grid item key={integration.id}>
            <IntegrationCard
              integration={integration}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onConnect={handleConnect}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          mb: 2,
        }}
      >
        Marketplace Integrations
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 2,
        }}
      >
        Integrations allow you to connect your account with various third-party
        services and platforms. These connections enhance your workflow by
        enabling data synchronization, automating tasks, and extending the
        functionality of your account. Manage your integrations here to
        streamline your operations and improve efficiency across your connected
        services.
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsCreateDialogOpen(true)}
        >
          Add Integration
        </Button>
      </Box>

      <Box sx={{ mt: 3 }}>{renderContent()}</Box>

      <CreateIntegration
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onCreateSuccess={handleCreateSuccess}
      />

      {/* Integration Edit Dialog */}
      <EditIntegration
        open={isEditDialogOpen}
        onClose={handleEditClose}
        integration={selectedIntegration}
        onUpdateSuccess={handleUpdateSuccess}
      />

      {/* GHL Edit Dialog */}
      <GHLEditDialog
        open={isGHLDialogOpen}
        onClose={handleEditClose}
        integration={selectedIntegration}
        onUpdateSuccess={handleUpdateSuccess}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will remove the connection between your account and {integrationToDelete?.integration_type}. You cannot undo this action.
            {integrationToDelete && (
              <>
                <Box sx={{ mt: 2, mb: 1 }}>
                  Here's what will happen:
                </Box>
                <Box component="ul" sx={{ mt: 0, mb: 2 }}>
                  {integrationToDelete.integration_type === "Facebook" && (
                    <>
                      <li>Stop management campaigns and adsets to your connected Facebook pages</li>
                      <li>Remove your Facebook pages</li>
                      <li>Remove your Facebook account</li>
                      <li>Remove your Facebook campaigns and ad sets</li>
                      <li>Remove your Facebook ad accounts</li>
                    </>
                  )}
                  {integrationToDelete.integration_type === "Go High Level" && (
                    <>
                      <li>Remove connections to your GHL locations</li>
                      <li>Remove custom field settings</li>
                      <li>Remove your GHL account</li>
                      <li>Remove GHL user connections from team members</li>
                      <li>Remove GHL location information from orders</li>
                    </>
                  )}
                  {integrationToDelete.integration_type === "Synthflow" && (
                    <>
                      <li>Remove your Synthflow assistants</li>
                      <li>Remove connections to your Synthflow locations</li>
                      <li>Remove all voice AI call information</li>
                    </>
                  )}
                  {integrationToDelete.integration_type === "Stripe" && (
                    <>
                      <li>Turn off any Product items connected to your Stripe products or prices</li>
                      <li>Remove your Stripe products</li>
                      <li>Remove your Stripe prices</li>
                    </>
                  )}
                </Box>
              </>
            )}
          </DialogContentText>
          {deleteError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {deleteError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Keep Integration</Button>
          <Button 
            onClick={handleConfirmDelete} 
            color="error"
            variant="contained"
            autoFocus
          >
            Remove Integration
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
