import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const FacebookOAuthCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (code && state) {
      const openerOrigin = localStorage.getItem('facebookAuthOpenerOrigin') || window.location.origin;
      
      if (!window.opener) {
        window.close();
        return;
      }

      try {
        window.opener.postMessage(
          { type: "FACEBOOK_OAUTH_CALLBACK", code, state },
          openerOrigin
        );
      } catch (error) {
        console.error("Error sending postMessage:", error);
      }
      
      localStorage.removeItem('facebookAuthOpenerOrigin');
      window.close();
    } else {
      window.close();
    }
  }, [location]);

  return <div>Processing Facebook OAuth callback...</div>;
};

export default FacebookOAuthCallback;
