import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CircularProgress, Alert, Box } from "@mui/material";
import customFetch from "./general/auth/customFetch";

const StripeProvider = ({ children, productId }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      // If no productId is provided, don't initialize Stripe
      if (!productId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await customFetch(`/v1/orders/payment-intent-key?product_id=${productId}`);

        if (!response.ok) {
          throw new Error("Failed to load Stripe configuration");
        }

        const data = await response.json();
        if (!data.success || !data.key) {
          throw new Error("Invalid Stripe configuration received");
        }

        const stripe = await loadStripe(data.key);
        if (!stripe) {
          throw new Error("Failed to initialize Stripe");
        }

        setStripePromise(stripe);
        setError(null); // Clear any previous errors
      } catch (error) {
        console.error("Error initializing Stripe:", error);
        setError(error.message || "Failed to initialize payment system");
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true); // Reset loading state when productId changes
    initializeStripe();
  }, [productId]); // Re-initialize when productId changes

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!stripePromise && productId) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">Unable to initialize payment system</Alert>
      </Box>
    );
  }

  return stripePromise ? (
    <Elements stripe={stripePromise}>{children}</Elements>
  ) : (
    children
  );
};

export default StripeProvider;
