import { loadStripe } from '@stripe/stripe-js';
import customFetch from '../auth/customFetch';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
let stripePromise = null;

export const getStripe = async (productId) => {
  if (!stripePromise) {
    const response = await customFetch(`/v1/orders/payment-intent-key?product_id=${productId}`);
    const data = await response.json();
    if (!data.success || !data.key) {
      throw new Error("Invalid Stripe configuration received");
    }
    stripePromise = loadStripe(data.key);
  }
  return stripePromise;
};
