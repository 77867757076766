import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  FormControl,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const AdminGhlUsers = () => {
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formValues, setFormValues] = useState({
    primary_location_id: "",
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const currentUser = decode();

  useEffect(() => {
    loadUsers();
    loadLocations();
  }, []);

  const loadUsers = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-users`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }
      const data = await response.json();
      const usersWithTenantLink = data.map((user) => ({
        ...user,
        linked_tenant_user: user.tenant_user_id !== null,
      }));
      setUsers(usersWithTenantLink);
    } catch (error) {
      console.error("Failed to load users:", error);
    }
  };

  const loadLocations = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch locations");
      }
      const data = await response.json();
      const locationsMap = data.reduce((acc, location) => {
        acc[location.ghl_location_id] = location;
        return acc;
      }, {});
      setLocations(locationsMap);
    } catch (error) {
      console.error("Failed to load locations:", error);
    }
  };

  const handleOpenDialog = (user = null) => {
    setEditMode(!!user);
    setSelectedUser(user);
    setFormValues(
      user
        ? { primary_location_id: user.primary_location_id }
        : { primary_location_id: "" }
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
    setFormValues({
      primary_location_id: "",
    });
  };

  const handleChange = (e, newValue) => {
    setFormValues({ 
      ...formValues, 
      primary_location_id: newValue ? newValue.ghl_location_id : "" 
    });
  };

  const handleSubmit = async () => {
    try {
      if (editMode && selectedUser) {
        const payload = {
          primary_location_id: formValues.primary_location_id,
        };
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/ghl-users/${selectedUser.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to update user");
        }
      }
      loadUsers();
      handleCloseDialog();
    } catch (error) {
      console.error("Failed to save user:", error);
    }
  };

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleUpdateTenantUsers = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-users/connecttenantusers`,
        {
          method: "PUT",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update tenant users");
      }
      await loadUsers();
      alert("Tenant users updated successfully!");
    } catch (error) {
      console.error("Failed to update tenant users:", error);
      alert("Failed to update tenant users");
    } finally {
      handleCloseConfirmDialog();
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1.2 },
    { field: "email", headerName: "Email", flex: 1.2 },
    {
      field: "primary_location_id",
      headerName: "Primary Location",
      flex: 1.2,
      renderCell: (params) => {
        const location = locations[params.row.primary_location_id];
        const displayText = location ? `${location.name}` : params.row.primary_location_id;
        return <span>{displayText}</span>;
      },
    },
    {
      field: "linked_tenant_user",
      headerName: "Linked Tenant User",
      flex: 0.8,
      type: "boolean",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleOpenDialog(params.row)}>
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "left", // left align text
        }}
      >
        Go High Level - Agency Users
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 2, // Theme spacing unit
          textAlign: "left", // Center align text
        }}
      >
        These are users which have been synced from your agency account after
        adding the {currentUser.tenant_description} marketplace application. These users are
        used to link sub-account and contact references within the business in a
        box application. Primary Location ID is used as the default location
        when an order is created by the given user.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenConfirmDialog}
        sx={{ marginBottom: 2 }}
      >
        Connect Tenant Users by Email
      </Button>
      <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
        <DataGridPro
          rows={users}
          columns={columns}
          pageSize={10}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          headerFilters
          pageSizeOptions={[10, 25, 50]}
          pagination
          autoHeight
        />
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editMode ? "Edit User" : "Add New User"}</DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>
          <FormControl fullWidth margin="dense">
            <Autocomplete
              id="primary-location-autocomplete"
              options={Object.values(locations)}
              getOptionLabel={(option) => `${option.name} (${option.ghl_location_id})`}
              value={Object.values(locations).find(loc => loc.ghl_location_id === formValues.primary_location_id) || null}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Primary Location"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            This action will connect tenant users by matching their email
            addresses with GHL users. This process cannot be undone except by
            manual effort. Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateTenantUsers} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminGhlUsers;
